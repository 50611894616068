import './pageTabs.css';

type PageTabsPropsType = {
   tabsArray: string[];
   tabActiveIndex: number;
   setTabActive: React.Dispatch<React.SetStateAction<number>>
}

const PageTabs = ({ tabsArray, tabActiveIndex, setTabActive }:PageTabsPropsType) => {
   return(
      <div className="pageTabs">
         {
            tabsArray.map(
               (tab, index) => {
                  return(
                     <button key={`tab__${index}`} className="pageTabs__tab"
                        aria-current={tabActiveIndex === index ? true : false}
                        onClick={
                           (event) => {
                              event.preventDefault();
                              setTabActive(index);
                           }
                        }
                     >
                        {tab}
                     </button>
                  )
               }
            )
         }
      </div>
   )
}

export default PageTabs;