import './alertGlass.css';

import { IoAlertCircleSharp } from 'react-icons/io5';

export type AlertGlassStateType = {
   status: boolean;
   frases?:string[];
   buttons?: {
      text: string;
      class: string;
      function: any;
      focus?: boolean;
   }[];
}

export type AlertGlassType = {
   status: boolean;
   frases?:string[];
   buttons?: {
      text: string;
      class: string;
      function: any;
      focus?: boolean;
   }[];
   setAlert: any;
}

const AlertGlass = ({status, frases, buttons, setAlert}:AlertGlassType) =>
{
   return(
      <>
         {
            status &&
            <div className="alertGlass__shadow">
               <div className='alertGlass'>
                  <div className="retaguarda_title">
                     <IoAlertCircleSharp /> Atenção!
                  </div>
                  { 
                     frases && frases.map(
                        (frase, index) => <p key={`frase_alertGlass__${index}`} className='alert_frase'>{frase}</p>
                     )
                  }
                  <div className="celula_buttons end">
                     {
                        buttons ?
                           buttons.map(
                              (button, index) => 
                                 <div key={`button_alert__${index}`} className="celula_20">
                                    <button
                                       key={`button_alertGlass__${button.text}`}
                                       className={`md ${button.class}`}
                                       onClick={(event) => {event.preventDefault();button.function()}}
                                       autoFocus={button.focus && button.focus}
                                    >
                                       {button.text}
                                    </button>
                                 </div>
                           )   
                        :
                        <button
                           key="button_alertGlass__alone"
                           className='md primary'
                           autoFocus
                           onClick={() => {setAlert({ status: false })}}
                        >
                           Ok
                        </button>
                     }
                  </div>
               </div>
            </div>
         }
      </>
   )
}

export default AlertGlass;