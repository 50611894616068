import React from 'react'
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { api } from '../functions/apiLocation';

import MenuRetaguarda from '../menuRetaguarda/menuRetaguarda';
import Loading, { LoadingType } from '../loading/loading';
import { RetaguardaUserContext } from '../../contexts/retaguardaContext/retaguardaContext';

import './retaguardaMiddleware.css';

const RetaguardaMiddleware: React.FC<{children: JSX.Element}> = ({children}) =>
{

   const [userRetaguarda, setUserRetaguarda] = React.useContext(RetaguardaUserContext);

   const [loading, setLoading] = React.useState<LoadingType>({status: true});

   const navigate = useNavigate();
   
   React.useEffect(
      () => {
         console.log(userRetaguarda)
         if(!userRetaguarda?.email || !userRetaguarda?.senha || !userRetaguarda?.nome || !userRetaguarda?.acessos)
         {
            navigate("/retaguarda/login");
         }
         if(userRetaguarda)
         Axios(
            {
               method: "post",
               url: api() + "/api/glassretaguarda/login_middleware.php",
               responseType: 'json',
               withCredentials: false,
               data: {
                  email: userRetaguarda.email,
                  senha: userRetaguarda.senha
               }
            }
         )
         .then(
            ({data}) => 
            {
               console.log(data);
               if(data.error?.code && data.error?.code !== 200)
               {
                  throw data;
               }
               else
               {
                  setUserRetaguarda && setUserRetaguarda(
                     {
                        ...data,
                        email: userRetaguarda.email,
                        senha: userRetaguarda.senha
                     }
                  );
                  setLoading({status: false});
               }
            }
         )
         .catch(
            (error) =>
            {
               navigate("/retaguarda/login");
            }
         )
      },
      []
   );

   return(
      <div className="retaguarda__container">
         <MenuRetaguarda />
         <Loading {...loading} frase='Autenticando usuário ...' />
         <div className="retaguarda__page">
            {children}
         </div>
      </div>
   );
}

export default RetaguardaMiddleware;