import React from "react";
import { createPortal } from "react-dom";
import { useForm } from 'react-hook-form';
import Axios from "axios";
import { api } from "../functions/apiLocation";

import Loading from "../loading/loading";
import { RetaguardaUserContext } from "../../contexts/retaguardaContext/retaguardaContext";

interface IPopUpProps {
   tituloPopUp: string;
   setPopControl: () => void;
   setInputValueFunction: (value: number) => void;
   tabelaPesquisa: string;
   opcoesPesquisa:
   {
      descricao: string;
      bd_column:string;
   }[]
   tabelaRetorno:
   {
      coluna_desc: string;
      bd_coluna:string;
   }[],
   excecoes?:
   {
      coluna:string;
      condicao:string;
      valor:string | number;
   }[]
}

export type PopUpPesquisaStateType = {
   status: boolean;
   tituloPopUp: string;
   tabelaPesquisa: string;
   setInputValueFunction: (value: number) => void;
   opcoesPesquisa:
   {
      descricao: string;
      bd_column: string;
   }[];
   tabelaRetorno:
   {
      coluna_desc: string;
      bd_coluna: string;
   }[];
   excecoes?:
   {
      coluna: string;
      condicao: string;
      valor: string | number;
   }[];
};

type PopFormValues = {
   inputPesquisa: string | number;
   selectFocoPesquisa: string;
   contexto: boolean;
   decrescente: boolean;
}

const PopUp = ({ tituloPopUp, setPopControl, setInputValueFunction, tabelaPesquisa, opcoesPesquisa, tabelaRetorno, excecoes }: IPopUpProps) => {
   const [container, setContainer] = React.useState<HTMLDivElement>();
   const newWindow = React.useRef<any>(window);

   const [userRetaguarda] = React.useContext(RetaguardaUserContext);

   const [retornoPesquisa, setRetornoPesquisa] = React.useState<any>();
   const [retornoEmpty, setRetornoEmpty] = React.useState(false);
   const [loading, setLoading] = React.useState<{status: boolean, frase?: string}>({status: false});

   const { register, handleSubmit, formState: { errors } } = useForm<PopFormValues>();

   const realizarPesquisa = handleSubmit(
      (formData) => {
         console.log(formData)
         if(userRetaguarda)
         {
            const arrColunas: string[] = [];
            for(let i = 0; i < tabelaRetorno.length; i++)
            {
               arrColunas.push(tabelaRetorno[i].bd_coluna);
            }
            setLoading({status: true, frase: "Pesquisando Conteúdo ..."});
   
            Axios(
               {
                  method: "post",
                  url: api()+"/api/glassretaguarda/reta_popUpPesquisa_search.php",
                  withCredentials: false,
                  data: {
                     email: userRetaguarda.email,
                     senha: userRetaguarda.senha,
                     ...formData,
                     tabela: tabelaPesquisa,
                     colunas: arrColunas,
                     excecoes: excecoes
                  }
               }
            )
            .then(
               ({data}) => 
               {
                  if(data.error && data.error.code !== 200)
                  {
                     throw data;
                  }
                  if(data.length < 1)
                  {
                     setRetornoEmpty(true);
                  }
                  else
                  {
                     setRetornoEmpty(false);
                     setRetornoPesquisa(data);
                  }
                  setLoading({status: false});
               }
            )
            .catch(
               (error) =>
               {
                  alert(error.msg)
                  console.log(error);
               }
            );
         }
      }
   );

   React.useEffect(() => {
      const div = document.createElement("div");
      setContainer(div);
   }, []);

   React.useEffect(() => 
   {
      if(container)
      {
         const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth;
         const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight;
         
         let widthPop = width * 2/3;
         if(widthPop > 768)
         {
            widthPop = 768;
         }
         let heightPop = height * 2/3;
         if(heightPop > 600)
         {
            heightPop = 600;
         }

         const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
         const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

         const systemZoom = width / window.screen.availWidth;
         const left = (width - widthPop) / 2 / systemZoom + dualScreenLeft;
         const top = (height - heightPop) / 2 / systemZoom + dualScreenTop;

         newWindow.current = window.open(
            "",
            "",
            ` popup=1,
               scrollbars=yes,
               width=${widthPop / systemZoom}, 
               height=${heightPop / systemZoom}, 
               top=${top}, 
               left=${left}
            `
         );
         
         setTimeout(
            async () => {
               if(newWindow.current)
                  await newWindow.current.document.getElementById("inputPesquisa").focus();
            },
            0
         )

         if(newWindow.current)
            newWindow.current.addEventListener("beforeunload", () => {
               setPopControl()
            })
         
         if(newWindow.current)
            newWindow.current.addEventListener("keydown", (e:KeyboardEvent) => {
               if(e.key === "Escape")
               {
                  if(newWindow.current)
                  {
                     setPopControl()
                     newWindow.current.close();
                  }
               }
            })

         let styleAttr = document.createElement("style");
         styleAttr.append(
            `
            body {
               margin: 0;
               -webkit-font-smoothing: antialiased;
               -moz-osx-font-smoothing: grayscale;
             }
             
             :root
             {
               /* COLORS */
               --branco: hsl(258, 100%, 97%);
               --amarelo: hsl(37, 96%, 61%);
               --cor-200-a: hsla(215, 77%, 46%, 0.37);
               --cor-400: hsl(215, 77%, 46%);
               --cor-500: hsl(216, 99%, 40%);
               --cor-800: hsl(216, 71%, 32%);
               --preto: hsl(0, 0%, 6%);
               --sucesso-out: hsl(120, 86%, 17%);
               --sucesso-in: hsl(120, 95%, 64%);
               --danger-in: hsl(0, 100%, 75%);
               --danger-out: hsl(0, 100%, 14%);
             }
             
             * {
                box-sizing: border-box;
                font-family: 'Josefin Sans', sans-serif;
             }
            
             h1,h2,h3,h4,p{
                margin:0;
             }
             
             .loading_container
             {
                position: fixed;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                background-color: hsla(219, 100%, 41%, 0.4);
             }
             
             .loading_body{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: var(--cor-800);
                padding: 1rem 2rem;
                border-radius: 1rem;
                color: var(--branco);
                font-size: 1.25rem;
                box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.527);
             }
             
             
             .lds-roller {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
              }
              .lds-roller div {
                animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                transform-origin: 40px 40px;
              }
              .lds-roller div:after {
                content: " ";
                display: block;
                position: absolute;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #fff;
                margin: -4px 0 0 -4px;
              }
              .lds-roller div:nth-child(1) {
                animation-delay: -0.036s;
              }
              .lds-roller div:nth-child(1):after {
                top: 63px;
                left: 63px;
              }
              .lds-roller div:nth-child(2) {
                animation-delay: -0.072s;
              }
              .lds-roller div:nth-child(2):after {
                top: 68px;
                left: 56px;
              }
              .lds-roller div:nth-child(3) {
                animation-delay: -0.108s;
              }
              .lds-roller div:nth-child(3):after {
                top: 71px;
                left: 48px;
              }
              .lds-roller div:nth-child(4) {
                animation-delay: -0.144s;
              }
              .lds-roller div:nth-child(4):after {
                top: 72px;
                left: 40px;
              }
              .lds-roller div:nth-child(5) {
                animation-delay: -0.18s;
              }
              .lds-roller div:nth-child(5):after {
                top: 71px;
                left: 32px;
              }
              .lds-roller div:nth-child(6) {
                animation-delay: -0.216s;
              }
              .lds-roller div:nth-child(6):after {
                top: 68px;
                left: 24px;
              }
              .lds-roller div:nth-child(7) {
                animation-delay: -0.252s;
              }
              .lds-roller div:nth-child(7):after {
                top: 63px;
                left: 17px;
              }
              .lds-roller div:nth-child(8) {
                animation-delay: -0.288s;
              }
              .lds-roller div:nth-child(8):after {
                top: 56px;
                left: 12px;
              }
              @keyframes lds-roller {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }

             .popUp_container{
                display:flex;
                width:100%;
                flex-direction: column;
                flex-wrap: nowrap;
             }

             .popUpPesquisa_header{
                width:100%;
                background-color: var(--cor-500);
                padding:.5rem 1.5rem;
                color: var(--branco);
             }

             .popUpPesquisa_filters{
                display:flex;
                width:100%;
                flex-direction: row;
                flex-wrap: wrap;
                padding:.5rem 1.5rem;
                padding-bottom: 0;
                column-gap: 2rem;
             }

             .celula_form{
               display: flex;
               flex-direction: column;
               flex-wrap: nowrap;
             }

             .celula_form input{
                padding: 0.25rem .5rem;
                font-size: 1.115rem;
                border:2px solid var(--cor-800);
                border-radius:.2rem;
             }
             
             .celula_form select{
                padding: 0.25rem .25rem;
                font-size: 0.85rem;
                text-transform: uppercase;
                border:2px solid var(--cor-800);
                border-radius:.2rem;
             }
             
             .celula_titulo{
                font-size:0.9rem;
                font-weight: 600;
             }

             .celula_error{
                padding:0.25rem 0.5rem;
                border: 1px solid red;
                background-color: hsla(0, 100%, 50%, 0.178);
             }

             .celula_33{
               width:33.33%;
             }

             .celula_100{
                width:100%;
             }

             .celula_checkbox{
                display: flex;
                justify-content:flex-start;
                flex-direction:row-reverse;
                float:left;
             }

             .celula_input_btn{
               display:flex;
               flex-direction:row;
               flex-wrap:nowrap;
             }

             .celula_input_btn input{
               width:80%;
               border-top-right-radius: 0px !important;
               border-bottom-right-radius: 0px !important;
               border-right: 0px;
             }
             .celula_input_btn button{
               width: 20%;
               height:100%;
               border-radius:0.2rem;
               border-top-left-radius: 0px !important;
               border-bottom-left-radius: 0px !important;
             }

             .btn_primary{
                border:none;
                background-color: var(--cor-800);
                outline:none;
                color: var(--branco);
                font-size: 0.9rem;
                font-weight: 600;
                cursor: pointer;
             }

             .btn_primary:hover{
                background-color: var(--cor-500);
             }

             .popUpPesquisa_table_wrap{
               width:calc(100% - 2rem);
               margin: 1rem;
               margin-top:0px;
               max-height: 50vh;
               overflow-y: auto;
             }

             .popUpPesquisa_table{
                width: 100%;
                border-colapse: colapse;
             }

             .popUpPesquisa_table thead{
                color: var(--branco);
                background-color: var(--cor-500);
                z-index:1;
             }
             .popUpPesquisa_table thead tr th{
                position:sticky;
                top:0;
                color: var(--branco);
                background-color: var(--cor-500);
             }

             .popUpPesquisa_table td,
             .popUpPesquisa_table th,
             {
                padding:0.1rem 0.4rem;
             }

             .text-align-right{
                text-align:right;
             }

             .popUpPesquisa_table tr:nth-child(even){
               background-color: var(--cor-200-a);
             }

             .popUpPesquisa_table tbody > tr:hover{
                background-color: var(--cor-400);
                color: var(--branco);
                cursor: pointer;
             }
            `
         );

         let title = document.createElement("title");
         title.append(tituloPopUp);
         
         if(newWindow.current)
         {
            newWindow.current.document.head.appendChild(title);
            newWindow.current.document.head.appendChild(styleAttr);
            newWindow.current.document.body.appendChild(container);
            const curWindow = newWindow.current;
            return () => curWindow.close();
         }
         else 
            return () => null
      }
   },
      [container]
   );

  return(
     <>
      {
         container &&
         createPortal(
            <div className="popUp_container">
               <Loading {...loading} />
               <form id="popForm" className="popUpPesquisa_form" onSubmit={(e) => {e.preventDefault();realizarPesquisa();e.stopPropagation();}}>
                  <div className="popUpPesquisa_header">
                     <h3>{tituloPopUp}</h3>
                  </div>
                  <div className="popUpPesquisa_filters">
                     <div className={`celula_form celula_33`}>
                        <label htmlFor='uf' className="celula_titulo">
                           Conteúdo:
                        </label>
                        <select 
                           id="uf"
                           {...register('selectFocoPesquisa', 
                              {
                                 required: true,
                                 value: opcoesPesquisa[0].bd_column
                              }
                           )}
                        >
                           {
                              opcoesPesquisa.map(
                                 (item, index) => {
                                    return(
                                       <option key={`opcoes_conteudo_${index}`} value={item.bd_column}>
                                          {item.descricao}
                                       </option>
                                    )
                                 }
                              )
                           }
                        </select>
                     </div>
                     
                     <div className={`celula_form celula_33`}>
                        <label className="celula_titulo" htmlFor="celula_form">
                           Opções:
                        </label>
                        <div>
                           <div className="celula_checkbox">
                              <label htmlFor="contexto">Habilitar Contexto</label>
                              <input type="checkbox" id="contexto"
                                 {
                                    ...register("contexto", 
                                       {
                                          value: false
                                       }
                                    )
                                 } 
                              />
                           </div>
                           <div className="celula_checkbox">
                              <label htmlFor="descrescente">Descrescente</label>
                              <input type="checkbox" id="contexto"
                                 {
                                    ...register("decrescente",
                                       {
                                          value: false
                                       }
                                    )
                                 } 
                              />
                           </div>
                        </div>
                     </div>
                     <div className="celula_form celula_100">
                        <label className="celula_titulo" htmlFor="inputPesquisa">
                           Pesquisa: <span className='required'>*</span>
                        </label>
                        <div className="celula_input_btn">
                           <input type="text" id="inputPesquisa"
                              autoFocus
                              {
                                 ...register(
                                    "inputPesquisa",
                                    {
                                       minLength: 1,
                                       required: true,
                                    }
                                 )
                              }
                           />
                           <button className="btn_primary" type="submit" form="popForm">Pesquisar</button>
                        </div>
                        { errors.inputPesquisa ? <div className="celula_error">Preencha o campo corretamente!</div> : "" }
                     </div>
                  </div>
               </form>
               {
                  retornoEmpty &&
                  <table className="popUpPesquisa_table">
                     <thead>
                        <tr>
                           <th>Nenhum resultado encontrado!</th>
                        </tr>
                     </thead>
                  </table>
               }
               {
                  !retornoEmpty && retornoPesquisa &&
                  <div className="popUpPesquisa_table_wrap">
                  <table className="popUpPesquisa_table">
                     <thead>
                        <tr>
                        {
                           tabelaRetorno.map(
                              (pesquisa, index) => {
                                 return(
                                    <th key={`head_table_return_${index}`}>{pesquisa.coluna_desc}</th>
                                 )
                              }
                           )
                        }
                        </tr>
                     </thead>
                     <tbody>
                        {
                           retornoPesquisa.map(
                              (pesquisa: any, index: number) => {
                                 return(
                                    <tr onClick={ () => {setInputValueFunction(pesquisa[tabelaRetorno[0].bd_coluna]);setPopControl()}} key={`line_table_return_${index}`}>
                                       {
                                          tabelaRetorno.map(
                                             (tabelaInfo, index) => {
                                                return(
                                                   <td className={typeof(pesquisa[tabelaInfo.bd_coluna]) === "number" ? "text-align-right" : ""} key={`cel_table_return_${index}`}>
                                                      {pesquisa[tabelaInfo.bd_coluna]}
                                                   </td>
                                                )
                                             }
                                          )
                                       }
                                    </tr>
                                 )
                              }
                           )
                        }
                     </tbody>
                  </table>
                  </div>
               }
            </div>
            ,
            container)
      }
     </>
  )
};

export default PopUp;