import React from 'react';
import Loading, { LoadingType } from '../../../components/loading/loading';

import { MdManageSearch } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';
import PopUp, { PopUpPesquisaStateType } from '../../../components/popUpPesquisa/popUpPesquisa';
import { pesquisarFromInput } from '../../../components/popUpPesquisa/popUpPesquisaFunctions';
import { RetaguardaUserContext } from '../../../contexts/retaguardaContext/retaguardaContext';
import { api } from '../../../components/functions/apiLocation';
import Axios from 'axios';
import AlertGlass, { AlertGlassStateType } from '../../../components/alertGlass/alertGlass';

import "../../../components/cssRetaguarda/retaguarda.css"
import { Input, Select } from '../../../components/hooksFormsInputs/hooksFormsInputs';

interface IForm
{
  id?: number | null;
  title: string;
  status: number;
}

const CadCategories = () => {
  const [loading, setLoading] = React.useState<LoadingType>({status: false});
  const [editing, setEditing] = React.useState(false);
  const [alertScr, setAlertScr] = React.useState<AlertGlassStateType>({ status: false });

  const [popUpPesquisa, setPopUpPesquisa] = React.useState<PopUpPesquisaStateType>();

  const [userRetaguarda] = React.useContext(RetaguardaUserContext);
  
  const { register, handleSubmit, control, resetField, setValue, getValues, reset, formState: { errors } } = useForm<IForm>({ mode: 'onBlur' });

  const showPopUpPesquisa_id = () => {
    setPopUpPesquisa(
      {
        status: true,
        tabelaPesquisa: "categories",
        opcoesPesquisa: [
          { descricao: "Categoria", bd_column: "title" },
          { descricao: "Código Interno", bd_column: "id" }
        ],
        tabelaRetorno: [
          { coluna_desc: "Código Interno", bd_coluna: "id" },
          { coluna_desc: "Categories", bd_coluna: "title" },
          { coluna_desc: "Status", bd_coluna: "status" }
        ],
        tituloPopUp: "Pesquisando Categorias:",
        setInputValueFunction: (value) => inputValueFunction_id(value, setAlertScr, true),
      }
    );
  }

  const inputValueFunction_id = (value:number, setAlertScr?: any, showLoading?: boolean) => {
    showLoading && setLoading({status: true, frase: "Procurando conteúdo ..."});
    pesquisarFromInput(value, userRetaguarda, "categories", ["id", "title", "status"], undefined, setAlertScr)
    .then(
      (response: any) => {
        if(!response.error)
        {
          reset(response[0]);
          setEditing(true);
          showLoading && setLoading({status: false});
        }
        if(response.error)
          reset();
          showLoading && setLoading({status: false});
      }
    )
  }

  const gravarProcesso = handleSubmit(
    (formData) => {
      if(userRetaguarda)
        setLoading({status: true, frase: "Salvando ..."});
      if(userRetaguarda)
      Axios(
        {
          method: "post",
          url: api() + "/api/glassretaguarda/save__cad_categorie.php",
          withCredentials: false,
          data: {
             email: userRetaguarda.email,
             senha: userRetaguarda.senha,
             ...formData
          },
        }
      )
      .then(
        ({data}) => {
          if(data.error)
            throw data;
          setAlertScr({status: true, frases: [data.msg]});
          if(!editing)
            inputValueFunction_id(data.inserted_id);
          setLoading({status: false});
        }
      )
      .catch(
        ({response}) => {
          setAlertScr({status: true, frases: [response.data.msg]});
          console.log(response);
        }
      )
    }
  );

  const handleShortcuts = React.useCallback(
    (e: KeyboardEvent) =>
    {
        
    },
    []
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleShortcuts);
    return () => window.removeEventListener('keydown', handleShortcuts);
  }, [handleShortcuts]);

  return (
    <div className="glasspage">
        <Loading {...loading} />
        <AlertGlass {...alertScr} setAlert={setAlertScr} />
        {
          popUpPesquisa && popUpPesquisa.status &&
          <PopUp
            {...popUpPesquisa}
            setPopControl={() => setPopUpPesquisa({...popUpPesquisa, status: false})}
          />
        }
        <h3 className='retaguarda_title'>Cadastro de Categorias</h3>
        
        <form onSubmit={gravarProcesso}>
          <div className="retaguarda_formulario">
              <div className="celula_form celula_20">
                <label htmlFor='id' className="celula_titulo ">
                  Cód. Interno:
                </label>
                <div className="celula_input_btn">
                    <input 
                      id="id"
                      type='tel'
                      inputMode='numeric'
                      className="input_busca_pop"
                      {
                          ...register(
                            "id",
                            {
                                min: 1,
                                max: 2000000,
                                required: editing,
                                onChange: (e) => e.target.value = e.target.value.replace(/[^\d]/g, ""),
                                onBlur: (e) => {
                                    resetField('id');
                                },

                            }
                          )
                      }
                      onKeyDown={ (e) => {
                          if(e.key === "F4")
                          {
                            showPopUpPesquisa_id();
                          }
                          if(e.key === "Enter")
                          {
                            e.preventDefault();
                            const page_id = getValues("id");
                            if(page_id)
                              inputValueFunction_id(page_id, setAlertScr, true);
                          }
                      }}
                    />
                    <button onClick={() => showPopUpPesquisa_id()} type="button" className="button_busca_pop"><MdManageSearch /></button>
                </div>
              </div>

              <Input 
                {
                  ...{
                    registerForm: register,
                    className: "celula_form celula_50",
                    input: "title",
                    label: "Categoria:",
                    options: {required: true, minLength: 4, maxLength: 150},
                    type: 'text',
                    error: errors.title
                  }
                }
                required
              />

              <Select 
                {
                  ...{
                    registerForm: register,
                    className: "celula_form celula_25",
                    input: "status",
                    label: "Status:",
                    selectOptions: [
                      {value: 1, option: "Ativa"},
                      {value: 0, option: "Inativa"},
                    ],
                    error: errors.status
                  }
                }
                required
              />

              <div className="celula_buttons celula_100">
                <div className="celula_form celula_25">
                    <button type='submit' className="md success">
                        Salvar {editing && "alterações"}
                    </button>
                </div>

                <div className={`celula_form celula_15`}>
                  <button onClick={() => {reset({
                    id: null,
                    title: "",
                    status: 1
                  }); setEditing(false);}} type='button' className="md primary">
                    Limpar
                  </button>
                </div>
              </div>

          </div>
        </form>
    </div>
  );
}

export default CadCategories;