import React from 'react';
import Axios from 'axios';
import { api } from '../functions/apiLocation';
import { RetaguardaUserContext } from '../../contexts/retaguardaContext/retaguardaContext';
import AlertGlass, { AlertGlassStateType } from '../alertGlass/alertGlass';

import { FcAcceptDatabase } from 'react-icons/fc'
import { MdDeleteForever } from 'react-icons/md';

import "./videosInPages.css";
import { Input, Select } from '../hooksFormsInputs/hooksFormsInputs';
import { useForm } from 'react-hook-form';
import Loading, { LoadingType } from '../loading/loading';
import { createPortal } from 'react-dom';
import { deepCopy } from '../functions/copyObjectDeep';

type VideosInPagesType = {
   page_id:  number | null | undefined;
}

type VideosInPagesStateType = {
   videoId: number;
   link: string;
   platform: string;
   orderPosition: number | null;
}

const VideosInPages = ({page_id}:VideosInPagesType) => {

   const [userRetaguarda] = React.useContext(RetaguardaUserContext);

   const [videosInPage, setVideosInPage] = React.useState<VideosInPagesStateType[]>();

   const [alertGlass, setAlertGlass] = React.useState<AlertGlassStateType>({status: false});
   const [loading, setLoading] = React.useState<LoadingType>({status: false});

   const refFormVideosPage = React.useRef<HTMLFormElement>(null);

   const { register: registerVideos, handleSubmit: handleSubmitVideos, reset, formState: { errors: errorsVideos } } = useForm<VideosInPagesStateType>({ mode: 'onBlur' });

   const saveVideoInPage = handleSubmitVideos(
      (objForm) => {
         setLoading({status: true, frase: "Registrando Vídeo..."})
         userRetaguarda &&
         Axios(
            {
               url: `${api()}/api/glassretaguarda/save__videos_in_pages.php`,
               method: "post",
               data: {
                  email: userRetaguarda.email,
                  senha: userRetaguarda.senha,
                  page_id: page_id,
                  ...objForm
               }
            }
         )
         .then(
            ({data}) => {
               setVideosInPage(
                  (prevState) => {
                     if(prevState)
                        return [...prevState, {...objForm, videoId: data.videoId}]
                     return [objForm]
                  }
               );
               reset(
                  {
                     link: "",
                     orderPosition: null,
                     platform: "1",
                     videoId: undefined
                  }
               );
            }
         )
         .catch(
            ({response}) => {
               setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
               console.log(response);
            }
         )
         .finally(
            () => setLoading({status: false})
         )
      }
   );

   const loadVideosInPage = (pageId: number) => {
      setLoading({status: true, frase: "Carregando lista de vídeos ..."})
      userRetaguarda &&
      Axios(
         {
            url: `${api()}/api/glassretaguarda/list__videos_in_pages.php`,
            method: "post",
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               page_id: pageId
            }
         }
      )
      .then(
         ({data}) => {
            setVideosInPage(data);
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
      .finally(
         () => setLoading({status: false})
      )
   }

   const removeVideo = (videoId: number, arrIndex: number) => {
      userRetaguarda &&
      Axios(
         {
            url: `${api()}/api/glassretaguarda/delete__uploads.php`,
            method: "post",
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               upload_id: videoId,
               tabela_bd: "pages_videos"
            }
         }
      )
      .then(
         (data) => {
            const copyVideosInPage = deepCopy(videosInPage);
            if(copyVideosInPage)
            {
               copyVideosInPage.splice(arrIndex, 1);
               setVideosInPage(copyVideosInPage);
            }
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
   }

   React.useEffect(
      () => {
         if(page_id)
            loadVideosInPage(page_id);
      }, 
      [page_id]
   )
   

   return(
      <>
         <AlertGlass {...alertGlass} setAlert={setAlertGlass} />
         <Loading {...loading} />
         <div className="menuCategories">
            <h2 style={{marginBottom: "1.5rem"}}>Adicionar vídeos à Pagina:</h2>
            <div className="menuCategories__form">

               <Input 
                  {
                     ...{
                        registerForm: registerVideos,
                        className: "celula_form celula_30",
                        formId: "formVideosPage",
                        input: "link",
                        label: "Link do Vídeo:",
                        options: {required: true, minLength: 4, maxLength: 250},
                        type: 'text',
                        error: errorsVideos.link
                     }
                  }
                  required
               />

               <Select 
                  {
                     ...{
                        registerForm: registerVideos,
                        className: "celula_form celula_25",
                        formId: "formVideosPage",
                        input: "platform",
                        label: "Plataforma:",
                        selectOptions: [
                           {value: 1, option: "YouTube"},
                        ],
                        error: errorsVideos.platform
                     }
                  }
                  required
               />

               <Input 
                  {
                     ...{
                        registerForm: registerVideos,
                        className: "celula_form celula_20",
                        formId: "formVideosPage",
                        input: "orderPosition",
                        label: "Ordem:",
                        options: {required: true, min: 1},
                        type: 'text',
                        error: errorsVideos.orderPosition
                     }
                  }
                  required
               />
               
               <div className="celula_buttons celula_15">
                  <button onClick={() => saveVideoInPage()} type='button' className='md primary'>
                     Adicionar Vídeo
                  </button>
               </div>
            </div>
            <div className="menuCategories__list">
               {
                  videosInPage && 
                  videosInPage.map(
                     (video, index) => {
                        return(
                           <div key={`categorie_in__${video.videoId}`} className="menuCategories__in">
                              <FcAcceptDatabase />
                              <span>{video.link}</span>
                              <span>{video.orderPosition}</span>
                              <div className="celula_buttons celula_5">
                                 <button className='sm error' onClick={
                                    (event) => {
                                       event.preventDefault();
                                       setAlertGlass({
                                          status: true,
                                          frases: [
                                             `Deseja remover o vídeo ${video.link} da Página?`,
                                             `*Esta ação não pode ser desfeita!`
                                          ],
                                          buttons: [
                                             {
                                                text: "Cancelar",
                                                function: () => setAlertGlass({status:false}),
                                                class: "primary",
                                                focus: true
                                             },
                                             {
                                                text: "Deletar",
                                                function: () => {
                                                   setAlertGlass({status:false});
                                                   removeVideo(video.videoId, index);
                                                },
                                                class: "error" 
                                             }
                                          ]
                                       });
                                    }
                                 }>
                                    <MdDeleteForever />
                                 </button>
                              </div>
                           </div>
                        );
                     }
                  )
               }
            </div>
         </div>
      </>
   )
}

export default VideosInPages;