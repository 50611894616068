import { Control, FieldError, UseFormGetValues, UseFormRegister, UseFormReset, UseFormResetField, UseFormSetValue } from "react-hook-form/dist/types"
import React from 'react';
import { MdManageSearch } from "react-icons/md";
import Loading, { LoadingType } from "../loading/loading";
import PopUp, { PopUpPesquisaStateType } from "../popUpPesquisa/popUpPesquisa";
import { pesquisarFromInput } from "../popUpPesquisa/popUpPesquisaFunctions";
import { IUserRetaguarda } from "../../contexts/retaguardaContext/retaguardaContext";
import AlertGlass, { AlertGlassStateType } from "../alertGlass/alertGlass";
import { Controller } from "react-hook-form";
import InputMask from 'react-input-mask';
import { dataValidator } from "../functions/inputValidators";

type InputTextType = {
   registerForm: UseFormRegister<any>,
   input: string;
   className: string;
   label: string;
   type: "text" | "password";
   options?: {};
   error: FieldError | undefined;
   required?: boolean;
   key?: string;
   formId?: string;
}

type InputDateType = {
   control: Control<any>;
   input: string;
   className: string;
   label: string;
   options?: {};
   error: FieldError | undefined;
   type: "date" | "datetime";
   required?: boolean;
   key?: string;
   formId?: string;
}

type SelectType = {
   registerForm: UseFormRegister<any>,
   input: string;
   className: string;
   label: string;
   selectOptions?: {
      option: string;
      value: string | number;
   }[];
   options?: {};
   error: FieldError | undefined;
   required?: boolean;
   key?: string;
   formId?: string;
}

type PopUpPesquisaType = {
   registerForm: UseFormRegister<any>;
   resetForm: UseFormReset<any>;
   resetFieldForm: UseFormResetField<any>;
   getValuesForm: UseFormGetValues<any>;
   setValueForm: UseFormSetValue<any>;
   userRetaguarda: IUserRetaguarda;
   input: string;
   className: string;
   label: string;
   bdTable: string;
   optionsPopUp: { descricao: string, bd_column: string }[];
   returnPopUp: { coluna_desc: string, bd_coluna: string }[];
   arrReturnPopForForm: string[];
   leftJoin?: {
      tableJoin: string;
      onMatch: string;
  }[];
   titlePopUp: string;
   options?: {};
   error: FieldError | undefined;
   required?: boolean;
   editingFormState: boolean;
   setEditingFormState: any;
   setTabState: any;
   columnShow?: string;
   value?: number | null;
   formId?: string;
}

export const Input = ({registerForm, input, className, label, type, options, error, required, key, formId }:InputTextType) => {
   return(
      <div key={key} className={`${className} ${error ? "celula_form_error" : ""}`}>
         <label htmlFor={input}>{label} {required ? <div className="celula_form__label_required">Requirido</div> : ""}</label>
         <input {...registerForm(input, {required: required ? true : false, ...options})} type={type} id={input} form={formId} />
         { error && (error.message ? <div className="celula_error">{error.message}</div> : <div className="celula_error">Preencha o campo corretamente!</div>)}
      </div>
   )
}

export const InputDate = ({control, input, className, label, options, type, error, required, key }:InputDateType) => {
   return(
      <div key={key} className={`${className} ${error ? "celula_form_error" : ""}`}>
         <label htmlFor={input}>{label} {required ? <div className="celula_form__label_required">Requirido</div> : ""}</label>
         <Controller
            control={control}
            name={input}
            rules={{
               pattern: type === "datetime"  ? /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}/ : /\d{2}\/\d{2}\/\d{4}/,
               required: required ? true : false,
               validate: 
               {
                  dataValid: value => dataValidator(value, type) || "Data informada é inválida!",
               },
               ...options
            }}
            render={({field: { onChange, onBlur, ref, value }}) => (
               <InputMask
                  mask={type === "datetime" ? "99/99/9999 99:99" : "99/99/9999"}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
               />
            )}
         />
         { error && (error.message ? <div className="celula_error">{error.message}</div> : <div className="celula_error">Preencha o campo corretamente!</div>)}
      </div>
   )
}

export const Select = ({ registerForm, input, className, label, selectOptions, options, error, required, key, formId  }: SelectType) => {
   return(
      <div key={key} className={`${className} ${error ? "celula_form_error" : ""}`}>
         <label htmlFor={input}>{label} {required ? <div className="celula_form__label_required">Requirido</div> : ""}</label>
         <select
            id={input}
            form={formId} 
            {...registerForm(input, {required: required ? true : false, ...options})}
         >
         {
            selectOptions &&
            selectOptions.map(
               (item, index) => {
               return(
                  <option key={`${key}__${index}`} value={item.value}>{item.option}</option>
               )
               }
            )
         }
         </select>
         { error && (error.message ? <div className="celula_error">{error.message}</div> : <div className="celula_error">Preencha o campo corretamente!</div>) }
      </div>
   )
}

export const PopUpPesquisaForm = ({ registerForm, resetForm, resetFieldForm, getValuesForm, setValueForm, userRetaguarda, input, className, label, bdTable, optionsPopUp, returnPopUp, arrReturnPopForForm, leftJoin, columnShow, titlePopUp, error, editingFormState, setEditingFormState, setTabState, required, options, value, formId  }: PopUpPesquisaType) => {

   const [loading, setLoading] = React.useState<LoadingType>({status: false});
   const [alertScr, setAlertScr] = React.useState<AlertGlassStateType>({status: false});

   const [inputValueShow, setInputValueShow] = React.useState<string>();

   const [popUpPesquisa, setPopUpPesquisa] = React.useState<PopUpPesquisaStateType>();

   React.useEffect(
      () => {
         if(getValuesForm(input))
            inputValueFunction_id(getValuesForm(input), undefined, true);
         if(!getValuesForm(input) && columnShow)
            setInputValueShow("")
      },
      [value]
   )

   const showPopUpPesquisa_id = () => {
      setPopUpPesquisa(
         {
            status: true,
            tabelaPesquisa: bdTable,
            opcoesPesquisa: optionsPopUp,
            tabelaRetorno: returnPopUp,
            tituloPopUp: titlePopUp,
            setInputValueFunction: (value) => inputValueFunction_id(value, undefined, true),
         }
      );
   }

   const inputValueFunction_id = (value:number, setAlertScr?: any, showLoading?: boolean) => {
     showLoading && setLoading({status: true, frase: "Procurando conteúdo ..."});
     pesquisarFromInput(value, userRetaguarda, bdTable, arrReturnPopForForm, leftJoin, setAlertScr)
     .then(
       (response: any) => {
         if(!response.error)
         {
            if(columnShow === undefined)
            {
               resetForm(response[0]);
               setEditingFormState(true);
               if(setTabState)
                  setTabState(0);
            }
            if(columnShow !== undefined)
            {
               const inputKey = arrReturnPopForForm[0];
               const resetValues = getValuesForm();
               resetForm({...resetValues, [input]: response[0][inputKey]});
               setInputValueShow(response[0][columnShow]);
            }
            showLoading && setLoading({status: false});
         }
         if(response.error && !columnShow)
            resetForm();
         showLoading && setLoading({status: false});
       }
     )
   }

   return(
      <>
         {
            popUpPesquisa && popUpPesquisa.status &&
            <PopUp
               {...popUpPesquisa}
               setPopControl={() => setPopUpPesquisa({...popUpPesquisa, status: false})}
            />
         }
         <AlertGlass {...alertScr} setAlert={setAlertScr} />
         <Loading {...loading} />
         <div className={`${className} ${error ? "celula_form_error" : ""}`}>
            <label htmlFor='id' className="celula_titulo ">
               {label}  {required ? <div className="celula_form__label_required">Requirido</div> : ""}
            </label>
            <div className="input_popup">
               <div className="celula_input_btn">
                  <input 
                     id={input}
                     form={formId} 
                     type='tel'
                     inputMode='numeric'
                     className="input_busca_pop"
                     {
                        ...registerForm(
                           input,
                           {
                              minLength: 1,
                              pattern: /\d/g,
                              required: editingFormState && !columnShow ? true : required ? true : false,
                              onChange: (e) => e.target.value = e.target.value.replace(/[^\d]/g, ""),
                              onBlur: (e) => {
                                 if(required || (editingFormState && !columnShow))
                                    resetFieldForm(input,  {keepError: true});
                                 else
                                    if(getValuesForm(input) === "")
                                       setInputValueShow("");
                              },
                              ...options
                           }
                        )
                     }
                     onKeyDown={ (e) => {
                        if(e.key === "F4")
                        {
                           showPopUpPesquisa_id();
                        }
                        if(e.key === "Enter")
                        {
                           e.preventDefault();
                           const id = getValuesForm(input);
                           if(id)
                              inputValueFunction_id(id, setAlertScr, true);
                        }
                     }}
                  />
                  <button onClick={() => showPopUpPesquisa_id()} type="button" className="button_busca_pop"><MdManageSearch /></button>
               </div>
               {
                  columnShow &&
                  <div className="input_popup__description">{inputValueShow}</div>
               } 
            </div>
            { error && (error.message ? <div className="celula_error">{error.message}</div> : <div className="celula_error">Preencha o campo corretamente!</div>) }
         </div>
      </>
   )

}