import React from 'react';
import './pages.css';
import { api } from '../../components/functions/apiLocation';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import Axios from 'axios';

import Loading from '../../components/loading/loading';
import { LoadingType } from '../../components/loading/loading';
import { Footer } from '../../components/footer/footer';

import PageFiles from '../../components/pageFiles/pageFiles';
import { IPageFilesType } from '../../components/pageFiles/pageFiles';

import PageGalerie from '../../components/pageGalerie/pageGalerie';
import { IPageGalerieType } from '../../components/pageGalerie/pageGalerie';

import PageMenu from '../../components/pageMenu/pageMenu';
import { PageMenuType } from '../../components/pageMenu/pageMenu';
import ErrorHandler, { ErrorHandlerType } from '../../components/errorHandler/errorHandler';
import PageVideo, { pageVideoType } from '../../components/pageVideos/pageVideos';

interface IPage {
  title: string;
  body: any;
  pageMenu?: PageMenuType[];
  imgs?: IPageGalerieType[];
  files?: IPageFilesType[];
  videos?: pageVideoType[];
}

const Pages: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  if(!searchParams.get("ref"))
  {
    navigate("/");
  }
  const page_id = parseInt(searchParams.get("ref") || "");
  const pageMenu = searchParams.get("pageMenu");
  const pageMenu_id = searchParams.get("pageMenuRef");

  const [pageContent, setPageContent] = React.useState<IPage>();
  const [loading, setLoading] = React.useState<LoadingType>({ status: true, frase: "Carregando conteúdo ..." });
  const [error, setError] = React.useState<ErrorHandlerType>({ status: false });

  React.useEffect(
    () => {
      window.scrollTo(0,0);
    },
    [page_id]
  );
  
  React.useEffect(
    () => {
       if(!page_id || page_id < 1)
       {
         navigate("/", {replace: true});
       }
       setLoading({ status: true, frase: "Carregando conteúdo ..." })
       Axios(
          {
             method: "post",
             url: api()+"/api/fillPages.php",
             withCredentials: false,
             data: {
                page_id: page_id
             }
          }
       )
       .then(
          ({data}:any) => {
            if(error.status)
              setError({status: false});
            setPageContent(data);
          }
       )
       .catch(
          (error) => {
            setError(
              {
                status: true,
                log: error
              }
            );
          }
       )
       .finally(
          () => setLoading({status:false})
       )
    },
    [page_id]
 );

  return(
    <>
      <Loading status={loading.status} frase={loading.frase} />
      <ErrorHandler status={error.status} log={error.log} />
      {
        page_id && pageContent?.title &&
        <div id="inicio" className="page page__margin_menu page__background_pattern">
          <div className="page__container_pages">
            <div className="page__header">
              <h1>{pageContent.title}</h1>
              <div className="page__path">
                <Link to="/">Início</Link>
                {
                  pageMenu && pageMenu_id &&
                  <>
                    <span>/</span>
                    <Link to={`/pagina?ref=${pageMenu_id}`}>{pageMenu}</Link>
                  </>
                }
                <span>/</span>
                <span>{pageContent.title}</span>
              </div>
            </div>
            <div className="pages">
              <div dangerouslySetInnerHTML={{__html: pageContent.body}} />
            </div>
            <PageMenu pageMenu={pageContent.pageMenu} pageParent_id={page_id} pageParent_title={pageContent.title} />
            <PageGalerie imgs={pageContent.imgs} />
            <PageVideo videos={pageContent.videos} />
            <PageFiles files={pageContent.files} />
          </div>
        <Footer />
        </div>
      }
    </>
  );
}

export default Pages;