import './loading.css';

export type LoadingType = {
   status: boolean;
   frase?:string;
}

const Loading = ({status,frase}:LoadingType) =>
{
   return(
      <>
      {
      status && 
         <div className="loading_container">
            <div className='loading_body'>
               <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
               { frase && <p className='loading_frase'>{frase}</p> }
            </div>
         </div>
      }
      </>
   )
}

export default Loading;