import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RetaguardaUserContext } from '../../../contexts/retaguardaContext/retaguardaContext';


const Dashboard:React.FC = () => 
{
   const [userRetaguarda, setUserRetaguarda] = React.useContext(RetaguardaUserContext);

   const saldarUsuario = (userName: string) =>
   {
      const hora = new Date().getHours();
      if(hora < 6 || hora > 18)
      {
         return `Boa noite, ${userName} !`
      }
      if(hora > 6 && hora < 12)
      {
         return `Bom dia, ${userName} !`
      }
      return `Boa tarde, ${userName} !`;
   }

   return(
      <h2>{userRetaguarda && saldarUsuario(userRetaguarda.nome)}</h2>
   )
}

export default Dashboard;