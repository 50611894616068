import React from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../assets/imgs/logoEstacao.png';
import { RetaguardaUserContext } from '../../../contexts/retaguardaContext/retaguardaContext';
import { api } from '../../../components/functions/apiLocation';
import Loading, { LoadingType } from '../../../components/loading/loading';

import './login.css';
import { Input } from '../../../components/hooksFormsInputs/hooksFormsInputs';
import GlassLogo from '../../../components/glassLogo/glassLogo';

interface ILogin{
   email: string;
   senha: string;
}

const Login:React.FC = () => 
{
   // USER CONTEXT 
   const [userRetaguarda, setUserRetaguarda] = React.useContext(RetaguardaUserContext);
   const [loading, setLoading] = React.useState<LoadingType>({status: false});

   const [loginError, setLoginError] = React.useState();
   
   const navigate = useNavigate();

   //FORM LOGIN REACT HOOKS FORMS
   const { register: registerLogin, handleSubmit: handleSubmitLogin, formState: {errors: errorsLogin} } = useForm<ILogin>();
   
   const doLogin = handleSubmitLogin(
      (formLogin) => {
         setLoading({status: true})
         Axios(
            {
               method: "post",
               url: api() + "/api/glassretaguarda/login_middleware.php",
               responseType: 'json',
               withCredentials: false,
               data: {...formLogin, doPassHash: true},
               validateStatus: () => true
            }
         )
         .then(
            ({data}) => 
            {
               if(data.error?.code && data.error?.code !== 200)
               {
                  throw data;
               }
               else
               {
                  setUserRetaguarda && setUserRetaguarda({...data});
                  navigate("/retaguarda/dashboard");
               }
            }
         )
         .catch(
            (error) =>
            {
               setLoading({status: false});
               setLoginError(error.error.msg);
            }
         )
      }
   );

   React.useEffect(
      () => {
         if(userRetaguarda?.email && userRetaguarda?.senha && userRetaguarda?.nome && userRetaguarda?.acessos)
         {
            navigate("/retaguarda/dashboard");
         }
      },
      [userRetaguarda]
   )

   return(
      <>
      <Loading {...loading} />
      <div className="glassLogin">

         <div className="glassLogin__left">
            <img className="logo_login" src={Logo} alt="Glass Retaguarda" />
            <GlassLogo />
         </div>
      
         <form onSubmit={doLogin}>
            <div className="glasspage glassLogin__form">
               <h3 className='retaguarda_title'>Retaguarda</h3>
               <Input 
                  {
                     ...{
                        className: "celula_form celula_100",
                        error: errorsLogin.email,
                        input: "email",
                        registerForm: registerLogin,
                        label: "E-mail:",
                        type: "text",
                        required: true,
                        options: {
                           pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
                           onChange: (e:any) => e.target.value.substring(0,255),
                           minLength: 5, 
                           maxLength: 255
                        }
                     }
                  }
               />

               <Input
                  {
                     ...{
                        className: "celula_form celula_100",
                        error: errorsLogin.senha,
                        input: "senha",
                        registerForm: registerLogin,
                        label: "Senha:",
                        type: "password",
                        required: true,
                        options: {
                           onChange: (e:any) => e.target.value.substring(0,255),
                           minLength: 3, 
                           maxLength: 255
                        }
                     }
                  }
               />

               {
                  loginError &&
                  <div className="celula_form celula_100 celula_error">
                     {loginError}
                  </div>
               }

               <div className="celula_buttons celula_100">
                  <button type="submit" className='md primary'>
                     Login
                  </button>
               </div>
            </div>
         </form>
      </div>
      </>
   )
}

export default Login;