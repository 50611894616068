import * as React from 'react';
import { Link } from 'react-router-dom';
import packageJson from './../../../package.json';
import { GiRotaryPhone } from 'react-icons/gi';
import { MdEmail } from 'react-icons/md';

import "./by.css";

interface ContatoProps {
   
}
 
const By: React.FC<ContatoProps> = () => {

   const [loaded, setLoaded] = React.useState<boolean>(false);
   const [cardHome, setCardHome] = React.useState<boolean>(false);
 
   React.useEffect(
     () => {
       setLoaded(!loaded)
       setTimeout(
         () => setCardHome(true), 250
       )
     }, []
   )
 
   return(
      <div className="page__container">
         <div className='image-container'>
            <div className={`page ${cardHome ? 'page_loaded' : ''}`}>
               <section className='page__title'>
                  <h2>TU NÃO DEVERIA ESTAR AQUI, MAS ...</h2>
                  <h4>que rufem os tambores .....</h4>
               </section>
               <div className='by' style={{textAlign: "center"}}>
                     <h3>Feito com ❤️ por <a href='https://www.instagram.com/viniciushiagogiongo/' target={'_blank'}>Vinicius Hiago Giongo</a></h3>
                     <h3><a href='https://www.instagram.com/giongodesign/' target={'_blank'}>Giongo Design & Sistemas</a></h3>
                     <h3>{new Date().getFullYear()} © Todos os direitos reservados.</h3>
                     <h3>Versão {packageJson.version}</h3>
                     <h2 style={{marginTop: "5rem", fontStyle: 'italic'}}>Totus Tuus Mariae!</h2>
               </div>
            </div>
         </div>
      </div>
   );
}
 
export default By;