import React from 'react';

import './errorHandler.css';
import opsSvg from '../../assets/svgs/ops_error.svg'
import {AiFillCaretDown} from 'react-icons/ai'

export type ErrorHandlerType = {
   status: boolean;
   log?:string;
   hasParent?: boolean
}

const ErrorHandler = ({ status, log, hasParent }:ErrorHandlerType) =>
{
   const [showLog, setShowLog] = React.useState(false);
   const logText = React.useRef<HTMLTextAreaElement>(null);
   const [logHeight, setLogHeight] = React.useState<number>();

   React.useEffect(
      () => {
         if(logText.current)
            setLogHeight(logText.current.scrollHeight)
      }, [logText.current]
   )

   return(
      <>
      {
      status &&
      <section id="errorHandler" className={`errorHandler page__margin_menu ${!hasParent && "errorHandler__container"}`}>
         <div className="errorHandler__body">
            <img src={opsSvg} alt="Ops, ocorreu um problema, falha nossa ..." />
            <h2>Ocorreu um erro, falha nossa ... <button onClick={() => window.location.reload()}>Tentar Novamente ?</button></h2>
            <div className="errorHandler__log" aria-expanded={showLog}>
               <button onClick={() => setShowLog(!showLog)}>Mostrar Informação Técnica 🤢 <AiFillCaretDown /></button>
               <textarea ref={logText} style={{height: `${logHeight}px`}} defaultValue={JSON.stringify(log, null, 4)} readOnly />
            </div>
         </div>
      </section>
      }
      </>
   )
}

export default ErrorHandler;