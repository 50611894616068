import Axios from 'axios';
import { api } from '../functions/apiLocation';
import { IUserRetaguarda } from '../../contexts/retaguardaContext/retaguardaContext';

const pesquisarFromInput = (id: number | undefined | null, userRetaguarda: IUserRetaguarda, tabela: string, colunas: string[], leftJoin?: { tableJoin: string, onMatch: string}[], setStateAlertScr?:any) =>
{
   return Axios(
      {
         method: "post",
         url: api()+"/api/glassretaguarda/reta_popUpPesquisa_quick_search.php",
         withCredentials: false,
         data: {
            email: userRetaguarda && userRetaguarda.email,
            senha: userRetaguarda && userRetaguarda.senha,
            tabela: tabela,
            colunas: colunas,
            leftJoin: leftJoin,
            id: id
         }
      }
   )
   .then(
      ({data}) => 
      {
         if(data.error && data.error.code !== 200)
         {
            throw data;
         }
         if(data.length < 1)
         {
            if(setStateAlertScr)
               setStateAlertScr({ status: true, frases: ["Nenhum cadastro com o código interno informado!"] });
            else
               alert("Nenhum cadastro com o código interno informado!");
            return { error: "Not Found" };
         }
         return data;
      }
   )
   .catch(
      (error) =>
      {
         if(setStateAlertScr)
            setStateAlertScr({ status: true, frases: [error.msg] });
         else
            alert(error.msg);
         console.log(error);
         return error;
      }
   );
}

export { pesquisarFromInput }