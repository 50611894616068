import React from 'react';
import './home.css';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { api } from '../../components/functions/apiLocation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay} from 'swiper';

import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';

import { Footer } from '../../components/footer/footer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Home: React.FC = () => {

  const navigate = useNavigate();

  const byEgg = (e:KeyboardEvent) => {
    if(e.ctrlKey && e.altKey && e.key === "b")
        navigate("/by", {replace: false});
  }

  React.useEffect(
    () => {
      window.addEventListener("keydown", byEgg);
      return () => {
        window.removeEventListener("keydown", byEgg);
      }
    }, []
  );

  return(
    <div id="inicio" className="page__container">
      <Swiper
        speed={600}
        pagination={{
          clickable: true
        }}
        spaceBetween={24}
        modules={[Pagination, Autoplay]}
        autoplay={
          {
            delay: 10000
          }
        }
        className="carousel"
      >
        <SwiperSlide>
          <div className="slide slide__img1">
            <div className="slide__text_container slide__text_background1 slide__text_right">
              <div className="slide__title">
                CONSERVAÇÃO COM desenvolvimento econômico-social
              </div>
              <div className="slide__description" style={{textAlign: "right"}}>
                Já conhece o programa ICMS Ecológico? <HashLink to="/#icms_eco" smooth >Clique aqui e saiba mais!</HashLink>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide slide__img2">
            <div className="slide__text_container slide__text_background2">
              <div className="slide__title">
                Pesquisador?
              </div>
              <div className="slide__description">
                Seja bem vindo! <Link to="/pagina?ref=1">Clique aqui</Link> para se interar sobre pesquisas nesta UC!
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide slide__img3">
            <div className="slide__text_container slide__text_right slide__text_background1">
              <div className="slide__title">
                Local de belezas!
              </div>
              <div className="slide__description">
                Acesse nossa galeria e se encante com o que a natureza nos presenteia! <Link to="/pagina?ref=7">Clique aqui!</Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section id="o_que_e" className='topic'>
        <h1 className="topic__title_parallax title_img1">
          <div className="topic__title_parallax_layer">
          </div>
          O que é uma Estação Ecológica?
        </h1>
        <div className="topic__text">
          <p>
            A Estação Ecológica é uma Unidade de Conservação (UC) de proteção integral, 
            sendo uma área de grande importância para a preservação dos ecosistemas naturais.
          </p>
          <p>
            Além da preservação da natureza, as estações ecológicas têm como função a realização de pesquisas científicas,
             sendo a sua visitação controlada, como visitas técnicas que tenham o objetivo educacional e estejam definidas
             no plano de manejo da unidade.
          </p>
          <p>
            A Estação Ecológica Municipal Paulo Pinto de Oliveira foi fundada em 2018 e hoje participa do programa ICMS Ecológico,
            aliando a concervação ambiental a uma série de benefícios.
          </p>
        </div>
      </section>

      <section id="icms_eco" className='topic'>
        <h1 className="topic__title_parallax title_img2">
          <div className="topic__title_parallax_layer">
          </div>
          ICMS Ecológico?
        </h1>
        <div className="topic__text">
          <p>O ICMS Ecológico é um mecanismo tributário que possibilita aos municípios acesso a parcelas 
            maiores que àquelas que já têm direito, dos recursos financeiros arrecadados pelos Estados através 
            do Imposto sobre Circulação de Mercadorias e Serviços, o ICMS, em razão do atendimento de determinados 
            critérios ambientais estabelecidos em leis estaduais. Não é um novo imposto, mas sim a introdução de 
            novos critérios de redistribuição de recursos do ICMS, que reflete o nível da atividade econômica nos 
            municípios em conjunto com a preservação do meio ambiente. 
          </p>
          <p>
            O ICMS Ecológio é um instrumento de política pública que recompensa aqueles que protegem o meio ambiente.
            Com isso, o município passa a receber recursos por parte o Governo do Estado do Paraná, que além de serem
            destinados para a conservação ambiental e aquisição da Estação Ecológica, também são designados para a área
            da saúde, educação e as demais secretarias municipais, aumentando a qualidade de vida do cidadão e o desenvolvimento
            do município.
          </p>
        </div>
      </section>

      <section id="localization" className='topic'>
        <h1 className="topic__title_parallax title_img3">
          <div className="topic__title_parallax_layer">
          </div>
          Localização
        </h1>
        <div className="topic__text topic__text_dont_break">
          <p>A Estação Ecológica está localizada próxima a Comunidade Porto Pinheiro e Santíssima Trindade, 
            às margens do alagado repressa Salto-Santiago.
          </p>
        </div>
        <iframe
          className='topic__localizationMap'
          title='Localização ESEC'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28796.068876485577!2d-52.280597725091255!3d-25.554740285763046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94efb970324d718d%3A0xba97884dcb9c95f3!2sEsta%C3%A7%C3%A3o%20Ecol%C3%B3gica%20Municipal%20Paulo%20Pinto%20de%20Oliveira!5e0!3m2!1sen!2sbr!4v1666877104092!5m2!1sen!2sbr"
          allowFullScreen={false} 
          loading="lazy"
          referrerPolicy='no-referrer-when-downgrade'
        >
        </iframe>
      </section>

      <Footer />
      
    </div>
  );
}

export default Home;