import React from 'react';
import { pageVideoType } from '../pageVideos';

const VideoDefault = ({ link, platform }: pageVideoType) => {

   const [imageLoaded, setImageLoaded] = React.useState(false);

   return(
      <>
         {
            {
               1: //Youtube
               <div className='videoGalerie__youtube'>
                  {
                     !imageLoaded &&
                     <div className="loading loading__gradient"></div>
                  }
                  <iframe
                     style={{width: "100%", height: "100%"}}
                     src={`https://www.youtube.com/embed/${link}`}
                     title="YouTube video player"
                     frameBorder="0"
                     allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     allowFullScreen
                     onLoad={() => setImageLoaded(!imageLoaded)}
                  />
               </div>
            }[platform]
         }
      </>
   )
}

export default VideoDefault;