import './glassLogo.css'

const GlassLogo = () => {
   return(
      <div className='glass__logo'>
         <p>GLASS</p>
         <span>by Giongo Sistemas</span>
      </div>
   );
}

export default GlassLogo;