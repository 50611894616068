import React from 'react';
import Axios from 'axios';
import { useDropzone } from 'react-dropzone';
import './fileUploader.css';

import { RetaguardaUserContext } from '../../contexts/retaguardaContext/retaguardaContext';
import { api } from '../functions/apiLocation';

import { GrDocumentWord, GrDocumentPdf } from 'react-icons/gr'
import { MdDeleteForever } from 'react-icons/md';
import AlertGlass, { AlertGlassStateType } from '../alertGlass/alertGlass';
import { deepCopy } from '../functions/copyObjectDeep';

export type fileUploaderType = {
}

interface IProps {
   uploadApiUrl: string;
   loadApiUrl: string;
   deleteApiUrl: string;
   updateApiUrl: string;
   tabela_bd: string;
   idRef: number | undefined | null;
   title: string;
}

type UploadsType = {
   files: {
      id: number;
      file_title: string;
      file_name: string;
      date: string;
   }[];
   imgs: {
      id: number;
      common_name: string;
      scientific_name?: string;
      scientist_name?: string;
      file_title: string;
      file_name: string; //imgs url
      date: string;
   }[]
}

const FileUploader = ({uploadApiUrl, loadApiUrl, deleteApiUrl, updateApiUrl, tabela_bd, title, idRef}:IProps) => {

   const [uploads, setUploads] = React.useState<UploadsType>();
   const [uploadProgress, setUploadProgress] = React.useState<number>();

   const [showToogle, setShowToogle] = React.useState(false); // imgs => true | files => false
   const [loadingUploads, setLoadingUploads] = React.useState(true);

   const [alertGlass, setAlertGlass] = React.useState<AlertGlassStateType>({status: false});

   const [userRetaguarda] = React.useContext(RetaguardaUserContext);

   const loadFilesImgs = () => {
      userRetaguarda &&
      Axios(
         {
            url: loadApiUrl,
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               idRef: idRef
            },
            withCredentials: false,
            method: "post"
         }
      )
      .then(
         ({data}) => 
         {
            setUploads(data);
            setLoadingUploads(false);
         }
      )
      .catch(
         ({response}) => 
         {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
   }

   //ReactDropzone
   const onDrop = React.useCallback(
      (acceptedFiles:any) => {
         const formData = new FormData();
         formData.append("ref_id", `${idRef}`);
         if(userRetaguarda)
         {
            formData.append("email", userRetaguarda.email);
            formData.append("senha", userRetaguarda.senha);
         }
         acceptedFiles.forEach(
            (file: any) => {
               formData.append("files[]", file, file.name);
            }
         )

         Axios(
            {
               url: uploadApiUrl,
               data: formData,
               withCredentials: false,
               method: "post",        
               headers: {
                  "Content-Type": "multipart/form-data",
               },
               onUploadProgress: (e) => { setUploadProgress(Math.round(e.loaded * 100 / e.total)) },
            }
         )
         .then(
            (data) => 
            {
               loadFilesImgs();
               setUploadProgress(undefined);
            }
         )
         .catch(
            ({response}) => 
            {
               setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
               console.log(response);
            }
         )
      }, []
   );

   const onDropRejected = React.useCallback(
      () => {
         setAlertGlass(
            {
               status: true,
               frases: [ "Arquivo inválido! Extensão não aceita." ]
            }
         )
      }, []
   );

   const { getRootProps, getInputProps, isDragActive } = useDropzone(
      {
         onDrop,
         onDropRejected,
         accept: {
            "application/pdf": ['.pdf'],
            "application/msword": ['.doc'],
            "image/png": ['.png'],
            "image/jpeg": ['.jpeg', '.jpg']
         },
      }
   );

   type DeleteUploadType = {
      upload_id: number;
      tabela_bd: string;
      type: "img" | "file";
   }

   const deleteUpload = ({upload_id, tabela_bd, type}:DeleteUploadType) => {
      userRetaguarda &&
      Axios(
         {
            url: deleteApiUrl,
            method: 'post',
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               upload_id: upload_id,
               tabela_bd: tabela_bd,
               type: type
            }
         }
      )
      .then(
         () => {
            const uploadsCopy = deepCopy(uploads);
            let index: number | undefined;
            switch(type)
            {
               case "file": {
                  index = uploadsCopy && uploadsCopy.files.findIndex(
                     (item) => item.id === upload_id
                  ) + 1;

                  index && uploadsCopy &&
                     uploadsCopy.files.splice(index - 1, 1)
                  break;
               }
               default: {
                  index = uploadsCopy && uploadsCopy.imgs.findIndex(
                     (item) => item.id === upload_id
                  ) + 1;
                  index && uploadsCopy &&
                     uploadsCopy.imgs.splice(index - 1, 1)
                  break;
               }
            }
            setUploads(uploadsCopy);
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )

   }
   
   type UpdateUploadsType = {
      event: React.FocusEvent<HTMLInputElement, Element>;
      beforeEditValue: any;
      indexValue: number;
      type: "img" | "file";
      upload_id: number;
      column_bd: any;
   }

   const updateUploads = ({event, beforeEditValue, indexValue, type, upload_id, column_bd}:UpdateUploadsType) => {
      if(beforeEditValue !== event.target.value)
      {
         userRetaguarda &&
         Axios(
            {
               url: updateApiUrl,
               method: 'post',
               data: {
                  email: userRetaguarda.email,
                  senha: userRetaguarda.senha,
                  upload_id: upload_id,
                  tabela_bd: tabela_bd,
                  column_bd: column_bd,
                  value: event.target.value
               }
            }
         )
         .then(
            ({data}) => {
               setAlertGlass({status: true, frases: [ data.msg ]});
               let uploadsCopy:any = [];
               uploadsCopy = deepCopy(uploads);
               switch(type)
               {
                  case "img": {
                     if(uploadsCopy && uploadsCopy.imgs)
                        uploadsCopy.imgs[indexValue][column_bd] = event.target.value;
                     break;
                  }
                  default: {
                     if(uploadsCopy && uploadsCopy.files)
                        uploadsCopy.files[indexValue][column_bd] = event.target.value;
                  }
               }
               setUploads(uploadsCopy);
            }
         )
         .catch(
            ({response}) => {
               setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
               console.log(response);
            }
         )
      }
   }

   React.useEffect(
      () => {
         loadFilesImgs()
      },
      []
   );

   return(
      <div className="fileUploader">
         <AlertGlass
            {
               ...{
                  status: alertGlass?.status,
                  buttons: alertGlass?.buttons,
                  frases: alertGlass?.frases,
                  setAlert: setAlertGlass
               }
            }
         />
         {/* <h2 className="fileUploader__title">
            {title}
         </h2> */}

         <div {...getRootProps()} className={`fileUploader__dropzone ${isDragActive ? 'fileUploader__dropzone_active' : ''}`}>
            <input {...getInputProps()} />
               {
               isDragActive ?
                  <p>Solte para carregar</p> :
                  <p>Solte seu arquivos aqui, ou clique para selecionar</p>
               }
               {
                  uploadProgress &&
                  <p>Realizando Upload: {uploadProgress}% concluido ...</p>
               }
         </div>
         {
            uploads && (uploads.imgs || uploads.files) &&
            <div className='fileUploads__options'>
               <span>Arquivos</span>
               <button className="fileUploads__switcher" onClick={(event) => {event.preventDefault(); setShowToogle(!showToogle)}} aria-checked={showToogle} />
               <span>Imagens</span>
            </div>
         }

         
         <div className="fileUploader__list">
         {
               showToogle ?
               uploads && uploads.imgs && uploads.imgs.map(
                  (img, index) => {
                     return(
                        <div key={`img_upload_${img.id}`} className="fileUploads__img">
                           <img src={api() + "/uploads/imgs/" + img.file_name} alt={img.file_title} />
                           <div className="celula_form celula_20">
                              <label key={`input_desc_${index}`} htmlFor="">
                                 Descrição Imagem:
                              </label>
                              <input type="text" defaultValue={img.file_title}
                                 onBlur={
                                    (event) => updateUploads({event: event, upload_id: img.id, beforeEditValue: img.file_title, column_bd: "file_title", indexValue: index, type: "img" })
                                 }
                              />
                           </div>
                           <div className="celula_form celula_20">
                              <label key={`input_common_name_${index}`} htmlFor="">
                                 Nome Comum:
                              </label>
                              <input type="text" defaultValue={img.common_name} 
                                 onBlur={
                                    (event) => updateUploads({event: event, upload_id: img.id, beforeEditValue: img.file_title, column_bd: "common_name", indexValue: index, type: "img" })
                                 }
                              />
                           </div>
                           <div className="celula_form celula_20">
                              <label key={`input_scientific_name_${index}`} htmlFor="">
                                 Nome Científico:
                              </label>
                              <input type="text" defaultValue={img.scientific_name}
                                 onBlur={
                                    (event) => updateUploads({event: event, upload_id: img.id, beforeEditValue: img.file_title, column_bd: "scientific_name", indexValue: index, type: "img" })
                                 }
                              />
                           </div>
                           <div className="celula_form celula_15">
                              <label key={`input_scientist_${index}`} htmlFor="">
                                 Cientista:
                              </label>
                              <input type="text" defaultValue={img.scientist_name}
                                 onBlur={
                                    (event) => updateUploads({event: event, upload_id: img.id, beforeEditValue: img.file_title, column_bd: "scientist_name", indexValue: index, type: "img" })
                                 }
                              />
                           </div>
                           <div className="celula_buttons celula_5">
                              <button className='sm error' onClick={
                                 (event) => {
                                    event.preventDefault();
                                    setAlertGlass({
                                       status: true,
                                       frases: [
                                          `Deseja excluir a imagem: ${img.file_title}?`,
                                          `*Esta ação não pode ser desfeita!`
                                       ],
                                       buttons: [
                                          {
                                             text: "Cancelar",
                                             function: () => setAlertGlass({status:false}),
                                             class: "primary",
                                             focus: true
                                          },
                                          {
                                             text: "Deletar",
                                             function: () => {
                                                setAlertGlass({status:false});
                                                deleteUpload({upload_id: img.id, tabela_bd: tabela_bd, type: "img"});
                                             },
                                             class: "error" 
                                          }
                                       ]
                                    });
                                 }
                              }>
                                 <MdDeleteForever />
                              </button>
                           </div>
                        </div>
                     )
                  }
               )
               :
               uploads && uploads.files && uploads.files.map(
                  (file, index) => {
                     return(
                        <div key={`file_upload_${file.id}`} className="fileUploads__file">
                           <div className="celula_form celula_50">
                              <label key={`input_desc_file_${index}`} htmlFor="">
                                 Descrição Arquivo:
                                 {
                                    {
                                       "pdf": <GrDocumentPdf />,
                                       "doc": <GrDocumentWord />,
                                    }[file.file_name.split(".")[file.file_name.split(".").length - 1]]
                                 }
                              </label>
                              <input type="text" defaultValue={file.file_title} 
                                 onBlur={
                                    (event) => updateUploads({event: event, upload_id: file.id, beforeEditValue: file.file_title, column_bd: "file_title", indexValue: index, type: "file" })
                                 }
                              />
                           </div>
                           <div className="celula_buttons celula_5">
                              <button className='sm error' onClick={
                                 (event) => {
                                    event.preventDefault();
                                    setAlertGlass({
                                       status: true,
                                       frases: [
                                          `Deseja excluir o arquivo: ${file.file_title}?`,
                                          `*Esta ação não pode ser desfeita!`
                                       ],
                                       buttons: [
                                          {
                                             text: "Cancelar",
                                             function: () => setAlertGlass({status:false}),
                                             class: "primary",
                                             focus: true
                                          },
                                          {
                                             text: "Deletar",
                                             function: () => {
                                                setAlertGlass({status:false});
                                                deleteUpload({upload_id: file.id, tabela_bd: tabela_bd, type: "file"});
                                             },
                                             class: "error" 
                                          }
                                       ]
                                    });
                                 }
                              }>
                                 <MdDeleteForever />
                              </button>
                           </div>
                        </div>
                     )
                  }
               )
         }
         </div>
      </div>
   )

}

export default FileUploader;