import React from 'react';

export type IUserRetaguarda = {
   email:string;
   senha:string;
   nome:string;
   acessos: string[];
 } | undefined;

type ContextRetaguarda = [
   IUserRetaguarda, React.Dispatch<React.SetStateAction<IUserRetaguarda>>
] | []

export const RetaguardaUserContext = React.createContext<ContextRetaguarda>([]);