import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from "../functions/isMobile";

import { MdOutlineDashboard, MdFindInPage } from 'react-icons/md';
import { AiFillDownCircle, AiOutlinePoweroff } from 'react-icons/ai';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';
import { BiEdit } from 'react-icons/bi';

import Logo from '../../assets/imgs/logoEstacao.png';

import "./menuRetaguarda.css";
import { RetaguardaUserContext } from '../../contexts/retaguardaContext/retaguardaContext';
import GlassLogo from '../glassLogo/glassLogo';

const MenuRetaguarda:React.FC = () => 
{
   const [userRetaguarda, setUserRetaguarda] = React.useContext(RetaguardaUserContext);
   const navigate = useNavigate();

   const [menuMobileOpen, setMenuMobileOpen] = React.useState(false);
   const [menuSubOpen, setMenuSubOpen] = React.useState(
      [
         {open: false},
         {open: false},
         {open: false}
      ]
   );

   const mobile = isMobile();

   const openCloseMenuItem = (pos:number) =>
   {
      const menuSubOpenCopy = menuSubOpen.slice();
      menuSubOpenCopy[pos].open = !menuSubOpenCopy[pos].open;
      setMenuSubOpen(menuSubOpenCopy);
   }

   const controlMenuMobile = () =>
   {
      setMenuMobileOpen(!menuMobileOpen);
   }

   return(
      <>
      <div className={`menu_body ${mobile ? menuMobileOpen ? "" : "menu_body_mobile_closed" : ""}`}>
         <div className="menu__header">
            <GlassLogo />
            <p className='celula_form__label_required'>ESEC - Porto Barreiro/PR</p>
         </div>
         <div className="menu_item">
            <Link onClick={controlMenuMobile} to={"/retaguarda/dashboard"} className="menu_item_title">
               <MdOutlineDashboard />
               <span>DASHBOARD</span>
               <span className='menu_item_title_not_sub' ></span>
            </Link>
         </div>
         <div className={`menu_item`}>
            <div onClick={() => openCloseMenuItem(0)} className="menu_item_title">
               <BiEdit />
               <span>CADASTROS</span>
               <AiFillDownCircle className={`menu_item_title_indicator ${menuSubOpen[0].open ? "turned" : ""}`} />
            </div>
            <div className={`menu_sub`} aria-expanded={menuSubOpen[0].open ? true : false}>
               <div className="menu__sub_container">
                  <Link onClick={controlMenuMobile} to={"/retaguarda/cadastros/paginas"} className="menu_sub_item">
                     Páginas
                  </Link>
                  <Link onClick={controlMenuMobile} to={"/retaguarda/cadastros/categorias"} className="menu_sub_item">
                     Categorias
                  </Link>
               </div>
               {/* <Link onClick={controlMenuMobile} to={"/retaguarda/cadastros/cargosProcessoSeletivo"} className="menu_sub_item">
                  Cargos Processo Seletivo
               </Link>
               <Link to={"/retaguarda/cadastros/tiposAnexos"} className="menu_sub_item">
                  Tipos de Anexos
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/cadastros/operadores"} className="menu_sub_item">
                  Operadores
               </Link> */}
            </div>
         </div>
         {/* <div className={`menu_item ${menuSubOpen[1].open ? `menu_item_open` : ``}`}>
            <div onClick={() => openCloseMenuItem(1)} className="menu_item_title">
               <MdFindInPage />
               <span>AÇÕES</span>
               <AiFillDownCircle className='menu_item_title_indicator' />
            </div>
            <div className="menu_sub">
               <Link onClick={controlMenuMobile} to={"/retaguarda/acoes/pontuacaoCandidatos"} className="menu_sub_item">
                  Verificação Aplicação Pontução Inscrições
               </Link>
            </div>
         </div>
         <div className={`menu_item ${menuSubOpen[2].open ? `menu_item_open` : ``}`}>
            <div onClick={() => openCloseMenuItem(2)} className="menu_item_title">
               <MdFindInPage />
               <span>LISTAS</span>
               <AiFillDownCircle className='menu_item_title_indicator' />
            </div>
            <div className="menu_sub">
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/candidatosCargos"} className="menu_sub_item">
                  Candidatos Cargo
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/candidatosPss"} className="menu_sub_item">
                  Candidatos por PSS
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/classProvisoria"} className="menu_sub_item">
                  Classificação Provisória
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/defInscricoes"} className="menu_sub_item">
                  Deferimento Inscrições
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/candidatosCabecalho"} className="menu_sub_item">
                  Inscrição Cabeçalho Candidato
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/candidatosDocumentos"} className="menu_sub_item">
                  Inscrição Completa Candidato
               </Link>
               <Link onClick={controlMenuMobile} to={"/retaguarda/listas/listaInscricoesDefObs"} className="menu_sub_item">
                  Observação e Situação das Inscrições por Cargos
               </Link>
            </div>
         </div> */}
         <div className="menu_item">
            <div 
               onClick={
                  () => 
                  { 
                     setUserRetaguarda && setUserRetaguarda(undefined);
                     navigate("/retaguarda/login");
                  }
               } 
               className="menu_item_title"
            >
               <AiOutlinePoweroff />
               <span>SAIR</span>
               <span className='menu_item_title_not_sub' ></span>
            </div>
         </div>
      </div>
      <div onClick={controlMenuMobile} className={mobile ? "retaguarda_menu_sombra_closed" : `retaguarda_menu_sombra`}>
         {
            mobile && 
            <>
               <span className='celula_form__label_required'>ESEC - Porto Barreiro/PR</span>
               {
                  menuMobileOpen ?
                  <IoMdClose />
                  :
                  <RiMenuUnfoldLine />
               }
            </>
         }
      </div>
      </>
   )
}

export default MenuRetaguarda;