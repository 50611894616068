import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import LogoReserva from '../../assets/imgs/logoEstacao.png';

import "./footer.css";

export const Footer = () => {
   return(
      <section id="contact" className='topic topic__contact'>
        <div className="contact">
          <div className="contact__logo">
            <img className="logo-reserva-menu-top" src={LogoReserva} alt="Logo da Estação Ecológica Municipal de Porto Barreiro: Paulo Pinto de Oliveira" />
            <div>
              <p>Estação Ecológica Paulo Pinto de Oliveira</p>
              <p>Porto Barreiro - Paraná</p>
            </div>
          </div>
          <div className='contact__address'>
            <p><BsTelephone /> (42) 3661-1091</p>
            <p><HiOutlineMail /> estacaoecologica@portobarreiro.pr.gov.br</p>
          </div>
        </div>
        <div className="contact__by">
            {new Date().getFullYear()} © Prefeitura Municipal de Porto Barreiro
        </div>
      </section>
   );
}