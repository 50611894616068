import React from "react";
import { HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { api } from "../functions/apiLocation";

import './pageMenu.css';

type PageMenuPageType = {
   pageChild_title: string;
   pageChild_id?: number;
   pageChild_link?: string;
   pageChild_link_blank?: boolean;
}

export type PageMenuType = {
   categorie_title: string;
   categorie_pages: PageMenuPageType[]
}

interface IProps {
   pageMenu?: PageMenuType[];
   pageParent_id: number;
   pageParent_title: string;
}

const PageMenu = ({pageMenu, pageParent_id, pageParent_title}:IProps) => {

   const [searchMenu, setSearchMenu] = React.useState("");

   const hasChildOnSearch = (categorie: PageMenuType) => {
      for(let i = 0; i < categorie.categorie_pages.length; i++)
      {
         if(categorie.categorie_pages[i].pageChild_title.match(new RegExp(searchMenu, "ig")))
            return true;
      }
      return false;
   }

   return(
      <>
      {
         pageMenu &&
         <section id="pageMenu" className="pageMenu">
            <div className="pageMenu__search">
               <HiSearch />
               <input onChange={(e) => setSearchMenu(e.target.value)} type="text" placeholder="Busca" />
            </div>
            {
               pageMenu.map(
                  (categorie, index) => {
                     if(hasChildOnSearch(categorie))
                        return(
                           <div key={`pageMenu__block_${index}`} className="pageMenu__categorie">
                              <h2 key={`pageMenu__block_title_${index}`} className="pageMenu__categorie_title">{categorie.categorie_title}</h2>
                              <div key={`pageMenu__categorie_block_${index}`} className="pageMenu__categorie_block">
                              {
                                 categorie.categorie_pages.map(
                                    (pageLink, index) => {
                                       if(pageLink.pageChild_title.match(new RegExp(searchMenu, "ig")))
                                       {
                                          if(pageLink.pageChild_link && pageLink.pageChild_link !== "/")
                                             return(
                                                <a 
                                                   key={`pageMenu__item_${index}`}
                                                   href={pageLink.pageChild_link}
                                                   target={pageLink.pageChild_link_blank ? "_blank" : undefined}
                                                   rel={pageLink.pageChild_link_blank ? "noopener noreferrer" : undefined}
                                                >
                                                   {pageLink.pageChild_title}
                                                </a>
                                             )
                                          return(
                                             <Link 
                                                key={`pageMenu__item_${index}`}
                                                to={pageLink.pageChild_link ? pageLink.pageChild_link : `/pagina?ref=${pageLink.pageChild_id}&pageMenu=${pageParent_title}&pageMenuRef=${pageParent_id}`}
                                                target={pageLink.pageChild_link_blank ? "_blank" : undefined}
                                                rel={pageLink.pageChild_link_blank ? "noopener noreferrer" : undefined}
                                             >
                                                {pageLink.pageChild_title}
                                             </Link>
                                          )
                                       }
                                       else
                                          return(null);
                                    }
                                 )
                              }
                              </div>
                           </div>
                        )
                     else
                        return(null);
                  }
               )
            }
         </section>
      }
      </>
   )

}

export default PageMenu;