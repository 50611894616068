//Require PHOTO PROVIDER
import React from 'react';
import { PhotoView } from 'react-photo-view';
import { api } from '../../functions/apiLocation';

export type ImageDefaultType = {
   index: number;
   file_name: string;
   file_title: string;
   common_name: string;
   scientific_name?: string;
   scientist_name?: string;
}

const ImageDefault = ({index, file_name, file_title, common_name, scientific_name, scientist_name}: ImageDefaultType) => {

   const [imageLoaded, setImageLoaded] = React.useState(false);

   return(
      <PhotoView key={`photoView_${index}`} src={api()+"/uploads/imgs/"+file_name}>
         <div className="photoGalerie__photo" key={`pageGaleria_photo_div_${index}_${file_name}`}>
            {
               !imageLoaded &&
                  <div key={`pageGaleria_placeholder_${index}`} className="loading loading__gradient"></div>
            }
            <img key={`pageGaleria_photo_${index}`} onLoad={() => setImageLoaded(true)} src={api()+"/uploads/imgs/"+file_name} alt={common_name} loading="lazy" />
            <span key={`pageGaleria_photo_desc_${index}`} >
               {common_name ? common_name : file_title}
               {scientific_name && <> (<i>{scientific_name}</i></>}
               {scientist_name && <>({scientist_name})</>}
               {scientific_name && <>)</>}
            </span>
         </div>
      </PhotoView>
   )
}

export default ImageDefault;