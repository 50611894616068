import './pageGalerie.css';
import { PhotoProvider } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import ImageDefault from "./components/ImageDefault.comp";

export type IPageGalerieType = {
   file_name: string; //img url
   file_title: string;
   common_name: string;
   scientific_name?: string;
   scientist_name?: string;
}

interface IProps {
   imgs?: IPageGalerieType[]
}


const PageGalerie = ({imgs}:IProps) => {
   return(
      <>
      {
         imgs &&
         <section id="pageGalerie" style={{display: "block"}} className="pageGalerie">
            <PhotoProvider >
            <div className="pageGalerie__galerie">
               {
                  imgs.map(
                     (file, index) => {
                        return(
                           <ImageDefault key={`photo__img_${index}_${file.file_name}`} {...file} index={index} />
                        )
                     }
                  )
               }
            </div>
            </PhotoProvider>
         </section>
      }
      </>
   )

}

export default PageGalerie;