const isMobile = () =>
{
   const widthScreen = window.innerWidth;
   if(widthScreen <= 768)
   {
      return true;
   }
   return false;
}

export { isMobile }