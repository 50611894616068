import React from 'react';
import Axios from 'axios';
import { api } from '../functions/apiLocation';
import { RetaguardaUserContext } from '../../contexts/retaguardaContext/retaguardaContext';
import AlertGlass, { AlertGlassStateType } from '../alertGlass/alertGlass';

import { FcAcceptDatabase } from 'react-icons/fc'
import { MdDeleteForever } from 'react-icons/md';

import "./menuCategories.css";

type MenuCategoriesType = {
   page_id:  number | null | undefined;
}

type CategoriesStateType = {
   out: {
      id: number;
      title: string;
   }[];
   in: {
      id: number;
      title: string;
   }[];
}

const MenuCategories = ({page_id}:MenuCategoriesType) => {

   const [userRetaguarda] = React.useContext(RetaguardaUserContext);

   const [categories, setCategories] = React.useState<CategoriesStateType>();

   const [categorie, setCategorie] = React.useState<string>("");

   const [alertGlass, setAlertGlass] = React.useState<AlertGlassStateType>({status: false});

   const loadCategories = () => {
      userRetaguarda &&
      Axios(
         {
            url: `${api()}/api/glassretaguarda/list__categories.php`,
            method: "post",
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               page_id: page_id
            }
         }
      )
      .then(
         ({data}) => {
            console.log(data)
            setCategories(data);
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
   }

   const saveCategorie = () => {
      userRetaguarda && categorie && page_id &&
      Axios(
         {
            url: `${api()}/api/glassretaguarda/save__categorie_pagemenu.php`,
            method: "post",
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               page_id: page_id,
               categorie_id: parseInt(categorie)
            }
         }
      )
      .then(
         ({data}) => {
            setCategorie("");
            loadCategories();
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
   }

   const removeCategorie = ({page_id, categorie_id}: {page_id: number | null | undefined, categorie_id: number}) => {
      userRetaguarda && page_id &&
      Axios(
         {
            url: `${api()}/api/glassretaguarda/delete__categorie_pagemenu.php`,
            method: "post",
            data: {
               email: userRetaguarda.email,
               senha: userRetaguarda.senha,
               page_id: page_id,
               categorie_id: categorie_id
            }
         }
      )
      .then(
         ({data}) => {
            loadCategories();
         }
      )
      .catch(
         ({response}) => {
            setAlertGlass({status: true, frases: [ response.data.msg, response.data.error ]});
            console.log(response);
         }
      )
   }

   React.useEffect(
      () => {
         loadCategories();
      }, 
      [page_id]
   )
   

   return(
      <>
         <AlertGlass {...alertGlass} setAlert={setAlertGlass} />
         <div className="menuCategories">
            <div className="menuCategories__form">
               <div className="celula_form celula_50">
                  <label htmlFor="">Adicionar Categoria ao Menu da Pagina:</label>
                  <select
                     onChange={
                        (e) => setCategorie(e.target.value)
                     }
                     value={categorie}
                  >
                     <option disabled value="">Selecionar</option>
                     {
                        categories && categories.out.map(
                           (categorie, index) => {
                              return <option key={`option_cat_${index}`} value={categorie.id}>{categorie.title}</option>
                           }
                        )
                     }
                  </select>
               </div>
               <div className="celula_buttons celula_10">
                  <button className='md primary'
                     onClick={
                        (e) => {
                           e.preventDefault();
                           saveCategorie();
                        }
                     }
                  >
                     Adicionar
                  </button>
               </div>
            </div>

            <div className="menuCategories__list">
               {
                  categories && categories.in && 
                  categories.in.map(
                     (categorie, index) => {
                        return(
                           <div key={`categorie_in__${index}`} className="menuCategories__in">
                              <FcAcceptDatabase /> {categorie.title}
                              
                              <div className="celula_buttons celula_5">
                                 <button className='sm error' onClick={
                                    (event) => {
                                       event.preventDefault();
                                       setAlertGlass({
                                          status: true,
                                          frases: [
                                             `Deseja remover a categoria ${categorie.title} do Menu da Página?`,
                                             `*Esta ação não pode ser desfeita!`
                                          ],
                                          buttons: [
                                             {
                                                text: "Cancelar",
                                                function: () => setAlertGlass({status:false}),
                                                class: "primary",
                                                focus: true
                                             },
                                             {
                                                text: "Deletar",
                                                function: () => {
                                                   setAlertGlass({status:false});
                                                   removeCategorie({page_id: page_id, categorie_id: categorie.id});
                                                },
                                                class: "error" 
                                             }
                                          ]
                                       });
                                    }
                                 }>
                                    <MdDeleteForever />
                                 </button>
                              </div>
                           </div>
                        );
                     }
                  )
               }
            </div>
         </div>
      </>
   )
}

export default MenuCategories;