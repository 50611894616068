import React from 'react';
import Loading, { LoadingType } from '../../../components/loading/loading';

import { MdManageSearch } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';
import PopUp, { PopUpPesquisaStateType } from '../../../components/popUpPesquisa/popUpPesquisa';
import { pesquisarFromInput } from '../../../components/popUpPesquisa/popUpPesquisaFunctions';
import { RetaguardaUserContext } from '../../../contexts/retaguardaContext/retaguardaContext';
import { api } from '../../../components/functions/apiLocation';
import Axios, { AxiosError } from 'axios';
import AlertGlass, { AlertGlassStateType } from '../../../components/alertGlass/alertGlass';

//React Quill Rich Text Editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import "../../../components/cssRetaguarda/retaguarda.css"
import { Input, Select } from '../../../components/hooksFormsInputs/hooksFormsInputs';
import FileUploader from '../../../components/fileUploader/fileUploader';
import PageTabs from '../../../components/pageTabs/pageTabs';
import MenuCategories from '../../../components/menuCategories/menuCategories';
import VideosInPages from '../../../components/videosInPages/videosInPages';

interface IForm
{
  id?: number | null;
  title: string;
  categorie_id: number | null;
  isaLink: boolean;
  link: string | null;
  link_new_tab: boolean;
  body: string;
  status: number;
}

type CategoriesApiType = {
  value: number;
  option: string;
}

const CadPages = () => {
  const [loading, setLoading] = React.useState<LoadingType>({status: false});
  const [editing, setEditing] = React.useState(false);
  const [isLink, setIsLink] = React.useState(false);
  const [alertScr, setAlertScr] = React.useState<AlertGlassStateType>({ status: false });

  const [categoriesOptions, setCategoriesOptions] = React.useState<CategoriesApiType[]>();

  const [popUpPesquisa, setPopUpPesquisa] = React.useState<PopUpPesquisaStateType>();

  const [userRetaguarda] = React.useContext(RetaguardaUserContext);

  const [pageTab, setPageTab] = React.useState(0);
  
  const { register, handleSubmit, control, resetField, setValue, getValues, reset, formState: { errors } } = useForm<IForm>({ mode: 'onBlur' });

  const showPopUpPesquisa_id = () => {
    setPopUpPesquisa(
      {
        status: true,
        tabelaPesquisa: "pages",
        opcoesPesquisa: [
          { descricao: "Título da Pagina", bd_column: "title" },
          { descricao: "Código Interno", bd_column: "id" }
        ],
        tabelaRetorno: [
          { coluna_desc: "Código Interno", bd_coluna: "id" },
          { coluna_desc: "Título da Pagina", bd_coluna: "title" },
          { coluna_desc: "Status", bd_coluna: "status" }
        ],
        tituloPopUp: "Pesquisando Páginas:",
        setInputValueFunction: (value) => inputValueFunction_id(value, setAlertScr, true),
      }
    );
  }

  const inputValueFunction_id = (value:number, setAlertScr?: any, showLoading?: boolean) => {
    showLoading && setLoading({status: true, frase: "Procurando conteúdo ..."});
    pesquisarFromInput(value, userRetaguarda, "pages", ["id", "title", "categorie_id", "link", "link_new_tab", "body", "status"], undefined, setAlertScr)
    .then(
      (response: any) => {
        if(!response.error)
        {
          console.log(response[0])
          reset(response[0]);
          if(response[0].link !== null && response[0].link !== "")
          {
            setValue("isaLink", true);
            setIsLink(true);
          }
          else
          {
            setValue("isaLink", false);
            setIsLink(false);
          }
          setEditing(true);
          setPageTab(0);
          showLoading && setLoading({status: false});
        }
        if(response.error)
          reset();
          showLoading && setLoading({status: false});
      }
    )
  }

  const gravarProcesso = handleSubmit(
    (formData) => {
      if(userRetaguarda)
        setLoading({status: true, frase: "Salvando ..."});
      if(userRetaguarda)
      Axios(
        {
          method: "post",
          url: api() + "/api/glassretaguarda/save__cad_page.php",
          withCredentials: false,
          data: {
             email: userRetaguarda.email,
             senha: userRetaguarda.senha,
             ...formData
          },
        }
      )
      .then(
        ({data}) => {
          if(data.error)
            throw data;
          setAlertScr({status: true, frases: [data.msg]});
          if(!editing)
            inputValueFunction_id(data.inserted_id);
          setLoading({status: false});
        }
      )
      .catch(
        (data) => {
          setAlertScr({status: true, frases: [data.msg]});
          console.log(data);
        }
      )
    }
  );

  const handleShortcuts = React.useCallback(
    (e: KeyboardEvent) =>
    {
        
    },
    []
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleShortcuts);
    return () => window.removeEventListener('keydown', handleShortcuts);
  }, [handleShortcuts]);

  //Request Categories
  React.useEffect(
    () => {
      userRetaguarda &&
      Axios(
        {
          method: "post",
          url: api() + "/api/glassretaguarda/list__categories.php",
          withCredentials: false,
          data: {
             email: userRetaguarda.email,
             senha: userRetaguarda.senha,
          },
        }
      )
      .then(
        ({data}:{data: CategoriesApiType[]}) => {
          setCategoriesOptions(data);
        }
      )
      .catch(
        ({response}: AxiosError) => {
          const error:any = response?.data;
          console.log(error.error);
          setAlertScr({status: true, frases: [error.msg], buttons: [{class: "primary", focus: true, text: "Tentar Novamente", function: () => window.location.reload()}]});
        }
      )
    }, [userRetaguarda]
  )

  return (
    <div className="glasspage">
        <Loading {...loading} />
        <AlertGlass {...alertScr} setAlert={setAlertScr} />
        {
          popUpPesquisa && popUpPesquisa.status &&
          <PopUp
            {...popUpPesquisa}
            setPopControl={() => setPopUpPesquisa({...popUpPesquisa, status: false})}
          />
        }
        <h3 className='retaguarda_title'>Cadastro de Páginas</h3>
        
        <form onSubmit={gravarProcesso}>
          <div className="retaguarda_formulario">
              <div className="celula_form celula_20">
                <label htmlFor='id' className="celula_titulo ">
                  Cód. Interno:
                </label>
                <div className="celula_input_btn">
                    <input 
                      id="id"
                      type='tel'
                      inputMode='numeric'
                      className="input_busca_pop"
                      {
                          ...register(
                            "id",
                            {
                                min: 1,
                                max: 2000000,
                                required: editing,
                                onChange: (e) => e.target.value = e.target.value.replace(/[^\d]/g, ""),
                                onBlur: (e) => {
                                    resetField('id');
                                },

                            }
                          )
                      }
                      onKeyDown={ (e) => {
                          if(e.key === "F4")
                          {
                            showPopUpPesquisa_id();
                          }
                          if(e.key === "Enter")
                          {
                            e.preventDefault();
                            const page_id = getValues("id");
                            if(page_id)
                              inputValueFunction_id(page_id, setAlertScr, true);
                          }
                      }}
                    />
                    <button onClick={() => showPopUpPesquisa_id()} type="button" className="button_busca_pop"><MdManageSearch /></button>
                </div>
              </div>

              <Input 
                {
                  ...{
                    registerForm: register,
                    className: "celula_form celula_50",
                    input: "title",
                    label: "Título:",
                    options: {required: true, minLength: 4, maxLength: 150},
                    type: 'text',
                    error: errors.title
                  }
                }
                required
              />

              <Select 
                {
                  ...{
                    registerForm: register,
                    className: "celula_form celula_25",
                    input: "status",
                    label: "Status:",
                    selectOptions: [
                      {value: 1, option: "Ativa"},
                      {value: 0, option: "Inativa"},
                    ],
                    error: errors.status
                  }
                }
                required
              />

              <PageTabs 
                {
                  ...{
                    tabActiveIndex: pageTab,
                    tabsArray: editing ? [
                      "Conteúdo", "Uploads", "Menu Categorias", "Vídeos"
                    ] : [ "Conteúdo" ],
                    setTabActive: setPageTab
                  }
                }
              />

              {
                {
                  0: 
                    <>
                    <Select 
                      {
                        ...{
                          registerForm: register,
                          className: "celula_form celula_25",
                          input: "categorie_id",
                          label: "Categoria:",
                          selectOptions: categoriesOptions ? [{option: "", value: ""}, ...categoriesOptions] : [{option: "", value: ""}],
                          error: errors.categorie_id
                        }
                      }
                    />
      
                    <Select 
                      {
                        ...{
                          registerForm: register,
                          className: "celula_form celula_20",
                          input: "isaLink",
                          label: "É um Link?",
                          selectOptions: [
                            {value: "false", option: "Não"},
                            {value: "true", option: "Sim"},
                          ],
                          options: {
                            onChange: () => {
                              setIsLink(!isLink)
                              if(isLink)
                                setValue("link", "");
                            }
                          },
                          error: errors.isaLink
                        }
                      }
                    />
      
                    {
                      isLink &&
                      <>
                        <Input 
                          {
                            ...{
                              registerForm: register,
                              className: "celula_form celula_45",
                              input: "link",
                              label: "Endereço do Link:",
                              type: "text",
                              error: errors.link
                            }
                          }
                          required
                        />
                        <Select 
                          {
                            ...{
                              registerForm: register,
                              className: "celula_form celula_30",
                              input: "link_new_tab",
                              label: "Abrir em nova Aba?",
                              selectOptions: [
                                {value: "1", option: "Sim"},
                                {value: "0", option: "Não"},
                              ],
                              error: errors.link_new_tab
                            }
                          }
                        />
                      </>
                    }
      
                    {
                      !isLink &&
                      <div className="celula_form celula__text_editor celula_100">
                        <label htmlFor="body">Conteúdo:</label>
                        <Controller     
                          name="body"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <ReactQuill
                              ref={ref}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              modules={
                                {
                                toolbar: [
                                  [{ 'size': ['small', false, 'large', 'huge'] }], 
                                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                                  ['link', 'image', 'video'],
                                  ['clean']
                                ]
                                }         
                              }  
                              theme="snow"
                            />
                          )}
                        />
                      </div>
                    }
                    </>,
                  1:
                    <>
                      <FileUploader {
                        ...{
                        uploadApiUrl: `${api()}/api/glassretaguarda/upload__page_files.php`,
                        updateApiUrl: `${api()}/api/glassretaguarda/update__uploads.php`,
                        deleteApiUrl: `${api()}/api/glassretaguarda/delete__uploads.php`,
                        loadApiUrl: `${api()}/api/glassretaguarda/list__page_files_imgs.php`,
                        tabela_bd: "pages_uploads",
                        idRef: getValues('id'),
                        title: "Carregar Arquivos"
                      }} />
                    </>,
                  2: <MenuCategories page_id={getValues('id')} />,
                  3: <VideosInPages page_id={getValues('id')} />
                }[pageTab]
              }

              <div className="celula_buttons celula_100">
                <div className="celula_form celula_25">
                    <button type='submit' className="md success">
                        Salvar {editing && "alterações"}
                    </button>
                </div>

                <div className={`celula_form celula_15`}>
                  <button onClick={() => {reset({
                    id: null,
                    title: "",
                    categorie_id: null,
                    link: null,
                    body: "",
                    link_new_tab: false,
                    status: 1
                  }); setEditing(false); setPageTab(0)}} type='button' className="md primary">
                    Limpar
                  </button>
                </div>
              </div>

          </div>
        </form>
    </div>
  );
}

export default CadPages;