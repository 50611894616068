import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Free
import Home from './pages/home/home';
import Pages from './pages/pages/pages';
import By from './pages/by/by';
import FixedMenu from './components/fixedMenu/fixedMenu.component';

//Retaguarda
import { IUserRetaguarda, RetaguardaUserContext } from './contexts/retaguardaContext/retaguardaContext';
import Login from './pages/retaguarda/login/login';
import Dashboard from './pages/retaguarda/dashboard/dashboard';
import RetaguardaMiddleware from './components/retaguardaMiddleware/retaguardaMiddleware';
import CadPages from './pages/retaguarda/cadPages/cadPages';
import CadCategories from './pages/retaguarda/cadCategories/cadCategories';

import './App.css';

function App() {

  const [userRetaguarda, setUserRetaguarda] = React.useState<IUserRetaguarda>();

  return(
    <RetaguardaUserContext.Provider value={[userRetaguarda, setUserRetaguarda]}>
      <Routes>
        <Route index={true} element={<><FixedMenu /><Home /></>} />
        <Route path='/pagina' element={<><FixedMenu/><Pages /></>} />
        <Route path='/by' element={<><FixedMenu/><By /></>} />
        <Route path='*' element={<><FixedMenu /><Home /></>} />
          <Route path='/retaguarda/login' element={<Login />} />
          <Route 
            path='/retaguarda/dashboard' 
            element={
              <RetaguardaMiddleware>
                <Dashboard />
              </RetaguardaMiddleware>
            }
          />
          <Route 
            path='/retaguarda/cadastros/paginas' 
            element={
              <RetaguardaMiddleware>
                <CadPages />
              </RetaguardaMiddleware>
            }
          />
          <Route 
            path='/retaguarda/cadastros/categorias' 
            element={
              <RetaguardaMiddleware>
                <CadCategories />
              </RetaguardaMiddleware>
            }
          />
          <Route path='/retaguarda/*' element={<Login />} />
      </Routes>
    </RetaguardaUserContext.Provider>
  );
}

export default App;
