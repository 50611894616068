import * as React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { isMobile } from '../functions/isMobile';
import Logo from './../../assets/imgs/logoEstacao.png';
import {HiMenu} from 'react-icons/hi';
import {MdClose} from 'react-icons/md';

import './fixedMenu.style.css';

const FixedMenu = () =>
{
   const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
   const mobile = isMobile();

   return(
      <div className="menu-fixed-container">
         <>
            <HashLink smooth to="/#inicio">
               <img className="logo-reserva-menu-top" src={Logo} alt="Logo da Estação Ecológica Municipal de Porto Barreiro: Paulo Pinto de Oliveira" />
            </HashLink>
            {
               !mobile &&
               <div className="menu__logo_title">
                  <p>Estação Ecológica Paulo Pinto de Oliveira</p>
                  <p>Porto Barreiro - Paraná</p>
               </div>
            }
         </>
         {
            mobile ?
            <button className={`button-mobile ${mobileMenuOpen ? 'button-mobile-active' : ""}`} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
               { mobileMenuOpen ? <MdClose /> : <HiMenu /> }
            </button>
            : null
         }
         <div className={ mobile ? `menu-fixed-links-container-mobile ${!mobileMenuOpen ? "menu-mobile-closed" : ""}` : 'menu-fixed-links-container' }>
            {
               mobile ? 
               <div>
                  <h3 style={{color: "var(--branco)"}} className="m-bottom-05rem">Estação Ecológica Paulo Pinto de Oliveira</h3>
                  <h3 style={{color: "var(--branco)"}} className='m-top-05rem'>Porto Barreiro - Paraná</h3>
               </div> : ""
            }
            <HashLink onClick={() => setMobileMenuOpen(false)} className={`menu-fixed-link ${mobile ? "menu-fixed-link-mobile" : ""}`} to="/#inicio" smooth >Início</HashLink>
            <Link onClick={() => setMobileMenuOpen(false)} className={`menu-fixed-link ${mobile ? "menu-fixed-link-mobile" : ""}`} to="/pagina?ref=1">Pesquisas</Link>
            <Link onClick={() => setMobileMenuOpen(false)} className={`menu-fixed-link ${mobile ? "menu-fixed-link-mobile" : ""}`} to="/pagina?ref=7">Galeria</Link>
            <Link onClick={() => setMobileMenuOpen(false)} className={`menu-fixed-link ${mobile ? "menu-fixed-link-mobile" : ""}`} to="/pagina?ref=11">Educação Ambiental</Link>
            <HashLink onClick={() => setMobileMenuOpen(false)} className={`menu-fixed-link ${mobile ? "menu-fixed-link-mobile" : ""}`} to="/#contact" smooth >Contato</HashLink>
         </div>
      </div>
   );
}

export default FixedMenu;