import React from "react";
import { AiFillFileText, AiOutlineCloudDownload } from "react-icons/ai";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { api } from "../functions/apiLocation";

import './pageFiles.css';

export type IPageFilesType = {
   file_title: string;
   file_name: string;
   date: string;
}

interface IProps {
   files?: IPageFilesType[]
}


const PageFiles = ({files}:IProps) => {

   const [searchFiles, setSearchFiles] = React.useState("");

   return(
      <>
      {
         files &&
         <section id="pageFiles" className="pageFiles">
            <div className="pageFiles__title">
               <h2><AiFillFileText />Arquivos anexados:</h2>
               <div className="pageFiles__search">
                  <HiOutlineDocumentSearch />
                  <input onChange={(e) => setSearchFiles(e.target.value)} type="text" placeholder="Busca" />
               </div>
            </div>
            {
               files.map(
                  (file, index) => {
                     if(file.file_title.match(new RegExp(searchFiles, "ig")))
                        return(
                           <div key={`pageFiles__file_${index}`} className="pageFiles__block">
                              <div className="pageFiles__block_info">
                                 <a href={`${api()}/uploads/files/${file.file_name}`} target="_blank" rel="noreferrer noopenner">{file.file_title}</a>
                                 <span>{file.date}</span>
                              </div>
                              <a className="pageFiles__block_download" href={`${api()}/uploads/files/${file.file_name}`} target="_blank" rel="noreferrer noopenner" download ><AiOutlineCloudDownload /> Download</a>
                           </div>
                        )
                     else
                        return null
                  }
               )
            }
         </section>
      }
      </>
   )

}

export default PageFiles;