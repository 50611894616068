import './pageVideos.css';
import 'react-photo-view/dist/react-photo-view.css';
import VideoDefault from "./components/videoDefault.comp";
import { MdOutlineVideoLibrary } from 'react-icons/md';

export type pageVideoType = {
   link: string; //img url
   platform: number;
}

interface IProps {
   videos?: pageVideoType[]
}


const PageVideo = ({videos}:IProps) => {
   return(
      <>
      {
         videos &&
         <section id="pageVideos" className="pageGalerie">
            <div className="pageFiles__title">
               <h2><MdOutlineVideoLibrary />Vídeos:</h2>
            </div>
            <div className="pageGalerie__galerie">
               {
                  videos.map(
                     (video, index) => {
                        return(
                           <VideoDefault key={`video__${index}`} {...video}/>
                        )
                     }
                  )
               }
            </div>
         </section>
      }
      </>
   )

}

export default PageVideo;